import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import * as actions from '../actions';
import { urls } from '.';
import ApiHelper from '../utilities/apiHelper';
import { Endpoints } from '../Constants';

class OAuthComplete extends Component{
  constructor(props){
    super(props);
    this.state = {};
  }
  componentDidMount = async () => {
    if (ApiHelper.isAuthExpired()){
      let refreshed = await ApiHelper.tryRefresh();
      if (!refreshed){
        this.props.history.push(urls.login);
        return;
      }
    }
    this.parseParams();
  }

  //METHODS
  parseParams = async () => {
    var str = this.props.history.location.search.substring(1);
    if (!str || str.length < 1 || str.indexOf('&') < 1){
      return;
    }
    var params = str.split('&');
    if (params.length !== 3){
      return;
    }

    let body = {};
    for(let p of params){
      let parts = p.split('=');
      if (!parts || parts.length !== 2){
        continue;
      }
  
      let key = parts[0];
      let val = parts[1];

      body[key] = val;
    }

    console.log(body);
    let response = await ApiHelper.put(Endpoints.UpdateQbAuth, body);
    if (response.success){
      alert('Updated QBO auth on server.');
    } else {
      alert('Failed to update QBO on server.')
    }
  }


  //EVENT HANDLERS
  onchange = (e) => {
    if (!e) { return; }
    this.setState({ [e.target.name]: e.target.value });
  }
  handleUpdates = (name, value) => {
    this.setState((prevState) => {
      let user = {...prevState.selectedUser};
      user[name] = value;

      return { selectedUser: user }
    });
  }

  //RENDER
  render() {
    return (
      <div className='content-view'>
        <div className='centered-container'>
          <div className='content-card singular-card' style={{width: '800px'}}>
            {/* MAIN VIEW */}
            <div>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <p className='page-header'>O Auth Complete!</p>
              </div>
              <Row>
                <Col xs='12'>
                  <p className='italic-text'>It is now safe to close this window.</p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
    return {
      user: state.user.session,
      users: state.user.list
    }
  }
const mapDispatchToProps = (dispatch) => {
  return {
    setUserSession: (obj) => dispatch(actions.setUserSession(obj)),
    logout: () => dispatch(actions.logout()),
    showLoading: () => dispatch(actions.showLoading()),
    closeLoading: () => dispatch(actions.closeLoading()),
    setUserList: (arr) => dispatch(actions.setUserList(arr))
  }
}
  
OAuthComplete.propTypes = { 
  user: PropTypes.object.isRequired
}
  
export default connect(mapStateToProps, mapDispatchToProps)(OAuthComplete);