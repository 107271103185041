import { combineReducers } from 'redux';
import modalReducer from './modalReducer';
import projectReducer from './projectReducer';
import reimbursableReducer from './reimbursableReducer';
import timeLogReducer from './timeLogReducer';
import userReducer from './userReducer';

const rootReducer = combineReducers({
    modal: modalReducer,
    projects: projectReducer,
    reimbursables: reimbursableReducer,
    timeLogs: timeLogReducer,
    user: userReducer,
});

export default rootReducer;