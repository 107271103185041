import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Input, Label, Row } from 'reactstrap';
import * as actions from '../actions';
import { urls } from '.';
import ApiHelper from '../utilities/apiHelper';
import { BaseButton, BaseDropDown, BaseInput } from '../components';
import { Endpoints } from '../Constants';
import Helpers from '../utilities/helpers';
import Upload from '../components/Upload';
import mime from 'mime-types';

class Reimbursables extends Component{
  constructor(props){
    super(props);
    this.state = {
      date: Helpers.GetTodaysDate(),
      editMode: false,
      fileText: '',
      paymentMethods: [{name: 'Check'}, {name: 'Credit Card'}],
      reimbursable: {},
      selectedPaymentMethod: {},
      selectedProject: {},
      uploads: [],
      uploadState: 'notstarted',
      UPLOADED_ARRAY: []
    };
  }
  componentDidMount = async () => {
    if (ApiHelper.isAuthExpired()){
      let refreshed = await ApiHelper.tryRefresh();
      if (!refreshed){
        this.props.history.push(urls.login);
        return;
      }
    }
    this.ensureDataForPage();
  }
  componentDidUpdate = () => {
    if (this.state.uploadState === 'started' && this.state.UPLOADED_ARRAY.length === this.state.uploads.length){
      this.saveReimbursables();
    }
    if (this.state.uploadState === 'done'){
      alert('Successfully saved the reimbursable.');
      this.props.closeLoading();
      this.props.history.push(urls.home);
    }
  }

  //METHODS
  ensureDataForPage = async () => {
    if (this.props.projects && this.props.projects.length > 0){
      return;
    }
    this.props.showLoading();
    let response = await ApiHelper.get(Endpoints.Projects);
    if (response.data){
      this.props.setProjectList(response.data);
    }
    this.props.closeLoading();
  }
  submitReimbursable = async (e) => {
    e.preventDefault();

    //validate
    if (!this.state.selectedProject.idproject || this.state.selectedProject.idproject < 1){
      alert('Please select a project for this reimbursable.');
      return;
    }
    if (!this.state.selectedPaymentMethod.name){
      alert('Please select a payment method for this reimbursable.');
      return;
    }
    if (!this.state.reimbursable.amount){
      alert('Please enter an amount for this reimbursable.');
      return;
    }

    //upload files; save obj if success
    this.props.showLoading();
    this.handleFiles('Please attach files for this reimbursable.');
  }
  handleFiles = async (noFilesMessage) => {
    //validate
    if (!this.state.uploads || this.state.uploads.length < 1){
      this.props.closeLoading();
      alert(noFilesMessage);
      return;
    }

    this.setState({uploadState: 'started'});

    //upload to s3
    for(let upload of this.state.uploads){
      let fileParts = upload.file.name.split('.');
      let fileName = fileParts[0];
      let fileType = mime.contentType(fileParts[1]);

      let response = await ApiHelper.post(Endpoints.PreSignedUrl, { fileName, fileType });

      if (response.success){
        var signedRequest = response.data.signedRequest;
        
        // Put the fileType in the headers for the upload
        var headers = { 'Content-Type': fileType };
        let request = await ApiHelper.put(signedRequest, upload.file, headers);

        if (request.success){
          this.setState((prevState) => {
            let list = prevState.UPLOADED_ARRAY;
            list.push({
              name: fileName, 
              amount: upload.amount, 
              url: response.data.url
            });
            return { UPLOADED_ARRAY: list }
          });
        } else {
          console.log('Error while uploading file ' + fileName + ' to S3.');
          console.log(request);
        }
      } else {
        console.log('Error while presigning url for upload of file: ' + fileName);
      }
    }
  }
  saveReimbursables = async () => {
    //Validate some stuff
    if (!this.state.selectedPaymentMethod.name){
      alert('Please choose a payment method for this reimbursable.');
      return;
    }


    let reimbursable = this.state.reimbursable;
    reimbursable.date = this.state.date;
    reimbursable.idproject = this.state.selectedProject.idproject;
    reimbursable.imageurl = this.state.UPLOADED_ARRAY[0].url;
    reimbursable.paymentmethod = this.state.selectedPaymentMethod.name;

    let response = await ApiHelper.post(Endpoints.Reimbursables, reimbursable);

    if (response.success){
      this.props.setReimbursables();
      this.setState({reimbursable: {...this.state.reimbursable, idreimbursable: response.data.idtreimbursable}, uploadState: 'done'});
    } else {
      alert('Failed to create the reimbursable.');
    }
    this.props.closeLoading();
  }
  editReimbursable = async (e) => {
    e.preventDefault();

    if (!this.state.selectedProject.idproject || this.state.selectedProject.idproject < 1){
      alert('Please select a project for this reimbursable.');
      return;
    }

    this.props.showLoading();

    let reimbursable = this.state.reimbursable;
    reimbursable.idproject = this.state.selectedProject.idproject;

    let response = await ApiHelper.put(Endpoints.Reimbursables, reimbursable);

    if (response.success){
      this.props.setReimbursables();
      alert('Edited!');
      this.props.history.push(urls.home);
      return;
    }

    alert('Failed to edit the reimbursable.');
    this.props.closeLoading();
  }
  deleteReimbursable = async () => {
    if (!window.confirm('Are you sure you want to delete this reimbursable?')){
      return;
    }

    this.props.showLoading();
    let response = await ApiHelper.delete(Endpoints.Reimbursables + '?id=' + this.state.reimbursable.idreimbursable);
    
    if (response.success){
      this.props.setReimbursables();
      alert('Deleted!');
      this.props.history.push(urls.home);
    } else {
      alert('Something went wrong while trying to delete this reimbursable.')
    }
    this.props.closeLoading();
  }
  fileSelectionChanged = (e) => {
    let fileElement = document.getElementById('fileinput');

    if (fileElement.files.length < 1){
      return;
    }
    let list = [];
    for(let file of fileElement.files){
      list.push({file});
    }

    this.setState({ 
      uploads: list
    }, this.updateUploadText);
  }
  openFilePicker = (e) => {
    e.preventDefault();
    let fileElem = document.getElementById('fileinput');
    fileElem.click();
  }

  //EVENT HANDLERS
  onchange = (e) => {
    if (!e) { return; }
    this.setState({ [e.target.name]: e.target.value });
  }
  handleUpdates = (name, value) => {
    this.setState((prevState) => {
      let reimbursable = {...prevState.reimbursable};
      reimbursable[name] = value;

      return { reimbursable }
    });
  }
  selectedProjectChanged = (selectedProject) => {
    this.setState({selectedProject});
  }
  selectedPaymentMethodChanged = (selectedPaymentMethod) => {
    this.setState({selectedPaymentMethod});
  }

  //RENDER
  render() {
    return (
      <div className='content-view'>
        <div className='centered-container'>
          <div className='content-card singular-card' style={{width: '800px'}}>
            <Row>
              <Col xs='12'>
                <p className='page-header temp-offset-for-input'>Reimbursables</p>
              </Col>
            </Row>
            <div style={{margin: '30px 0px 0px 0px'}}>
              <BaseDropDown items={this.props.projects} 
                emptyText='No Projects to Show' 
                placeholder={this.state.selectedProject.title ? this.state.selectedProject.title : 'Select a project'} 
                titleProp='title' 
                selectionChanged={this.selectedProjectChanged}/>
            </div>
            <div style={{margin: '20px 0px 0px 0px'}}>
              <BaseDropDown items={this.state.paymentMethods} 
                emptyText='No Payment Methods' 
                placeholder={this.state.selectedPaymentMethod.name ? this.state.selectedPaymentMethod.name : 'Payment Method'} 
                titleProp='name' 
                selectionChanged={this.selectedPaymentMethodChanged}/>
            </div>
            <Row hidden={this.state.clockInOnly}>
              <Col xs='12' md='6'>
                <Label for='date' className='field-active'>{'Date'}</Label>
                <Input type='date' name='date' value={this.state.date} className='datepicker' onChange={this.onchange}/>
              </Col>
              <Col xs='12' md='6'>
                <BaseInput name='amount' changeHandler={this.handleUpdates} label='Amount' type='text'/>
              </Col>
            </Row>
            <Row>
              <Col xs='12'>
                <BaseInput name='description' changeHandler={this.handleUpdates} label='Description' type='textarea'/>
              </Col>
            </Row>
            <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', marginTop: '4em'}}>
              <span>
                <input type='file' id='fileinput' style={{display: 'none'}} onChange={this.fileSelectionChanged}/>
                <BaseButton content='Attach File' classes='reasonably-sized-button' onClick={this.openFilePicker}/>
                <p style={{fontStyle: 'italic', padding: '0px', margin: '0px'}}>{this.state.fileText}</p>
                {
                  this.state.uploads && this.state.uploads.length > 0 ?
                    this.state.uploads.map((obj, i) =>
                      <Upload key={'upload'+i} item={obj} label={`${obj.file.name}`} removeUpload={this.removeUpload} uploadState={this.state.uploadState}/>
                    ) : null
                }
              </span>
              <span hidden={this.state.clockInOnly || this.state.editMode}>
                <BaseButton content='Save Reimbursable' classes='reasonably-sized-button' onClick={this.submitReimbursable}/>
              </span>
              <span hidden={!this.state.editMode}>
                <BaseButton content='Save Edits' classes='reasonably-sized-button' onClick={this.editReimbursable}/>
              </span>
              <span hidden={!this.state.editMode}>
                <BaseButton content='Delete' classes='reasonably-sized-button delete-button' onClick={this.deleteReimbursable}/>
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
    return {
      user: state.user.session,
      projects: state.projects.list,
      reimbursables: state.reimbursables.list
    }
  }
const mapDispatchToProps = (dispatch) => {
  return {
    setUserSession: (obj) => dispatch(actions.setUserSession(obj)),
    logout: () => dispatch(actions.logout()),
    showLoading: () => dispatch(actions.showLoading()),
    closeLoading: () => dispatch(actions.closeLoading()),
    setReimbursables: (arr) => dispatch(actions.setReimbursableList(arr)),
    setProjectList: (arr) => dispatch(actions.setProjectList(arr))
  }
}
  
Reimbursables.propTypes = { 
  user: PropTypes.object.isRequired
}
  
  export default connect(mapStateToProps, mapDispatchToProps)(Reimbursables);