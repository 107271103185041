import axios from 'axios';
import { Endpoints } from '../Constants';
import Logger from './loggingUtility';

class _helper {
    constructor () {
        this.configure();
        // this.instance = null;
    }


    // CONFIG METHODS
    configure = () => {
        this.instance = null;

        this.instance = axios.create({
            headers: {
                'Accept': 'application/json',
                'x-st-client': 'haastime',
                'x-st-clientversion': '1.0.0.0'
            }
        });
    }
    reconfigure = () => {
        if (!this.getAuthToken()){
            Logger.LogError('auth token not found');
        }
        this.configure();
    }
    unconfigure = () => {
        this.instance = null;

        this.deleteAuthToken();
        this.deleteRefreshToken();

        Logger.LogWarning('API helper was unconfigured.');
    }

    //REST METHODS
    get = async (url) => {
        try{
            let options = this.getRequestTokens();
            let res = await this.instance.get(url, options);
            if (res.data.token){
                this.saveAuthToken(res.data.token);
                this.setAuthExpiry();
            }
            return this.formatResponse(res);
        }
        catch (error){
            if (!error.response){
                Logger.LogError('NON SERVER ERROR');
                Logger.LogError(error);
                return this.formatResponse(null, error);
            }

            //Handle unauthorized
            if (error.response.status === 401) {
                this.setAuthExpiry(1); //force next request to send refresh token
            }

            //Handle new auth token on errors like not found, bad request, etc.
            if (error.response && error.response.data && error.response.data.token){
                this.saveAuthToken(error.response.data.token);
                this.setAuthExpiry();
            }

            return this.formatResponse(null, error);
        }
    }
    put = async (url, body, headers) => {
        try{
            let options = this.getRequestTokens();
            if (headers){
                options = {...options.headers, headers}
            }
            let res = await this.instance.put(url, body, options);
            if (res.data.token){
                this.saveAuthToken(res.data.token);
                this.setAuthExpiry();
            }
            return this.formatResponse(res);
        }
        catch (error){
            if (!error.response){
                Logger.LogError('NON SERVER ERROR');
                Logger.LogError(error);
                return this.formatResponse(null, error);
            }

            //Handle unauthorized
            if (error.response.status === 401) {
                this.setAuthExpiry(1); //force next request to send refresh token
            }

            //Handle new auth token on errors like not found, bad request, etc.
            if (error.response && error.response.data && error.response.data.token){
                this.saveAuthToken(error.resposne.data.token);
                this.setAuthExpiry();
            }

            return this.formatResponse(null, error);
        }
    }
    post = async (url, body, headers) => {
        try{
            let options = this.getRequestTokens();
            if (headers){
                options = {...options.headers, headers}
            }
            let res = await this.instance.post(url, body, options);
            if (res.data.token){
                this.saveAuthToken(res.data.token);
                this.setAuthExpiry();
            }
            return this.formatResponse(res);
        }
        catch (error){
            if (!error.response){
                Logger.LogError('NON SERVER ERROR');
                Logger.LogError(error);
                return this.formatResponse(null, error);
            }

            //Handle unauthorized
            if (error.response.status === 401) {
                this.setAuthExpiry(1); //force next request to send refresh token
            }

            //Handle new auth token on errors like not found, bad request, etc.
            if (error.response && error.response.data && error.response.data.token){
                this.saveAuthToken(error.resposne.data.token);
                this.setAuthExpiry();
            }

            return this.formatResponse(null, error);
        }
    }
    delete = async (url) => {
        try{
            let options = this.getRequestTokens();
            let res = await this.instance.delete(url, options);
            if (res.data.token){
                this.saveAuthToken(res.data.token);
                this.setAuthExpiry();
            }
            return this.formatResponse(res);
        }
        catch (error){
            if (!error.response){
                Logger.LogError('NON SERVER ERROR');
                Logger.LogError(error);
                return this.formatResponse(null, error);
            }

            //Handle unauthorized
            if (error.response.status === 401) {
                this.setAuthExpiry(1); //force next request to send refresh token
            }

            //Handle new auth token on errors like not found, bad request, etc.
            if (error.response && error.response.data && error.response.data.token){
                this.saveAuthToken(error.resposne.data.token);
                this.setAuthExpiry();
            }

            // return { status: error.response.status };
            return this.formatResponse(null, error);
        }
    }

    //HELPERS
    tryRefresh = async () => {
        let response = await this.post(Endpoints.RefreshAuthToken, {refresh: ApiHelper.getRefreshToken()});
        return response.success;
    }
    getRequestTokens = () => {
        let options = {
            headers: {
                "x-st-authtoken": this.getAuthToken()
            }
        };
        if (this.isAuthExpired()){
            options.headers = {...options.headers, "x-st-refreshtoken" : this.getRefreshToken()}
        }
        return options;
    }
    formatResponse = (res, err) => {
        let response = {};
        if (err && !err.response){
            return {status: 400, success: false};
        }
        if (err && err.response){
            return {status: err.response.status, success: false}
        }
        response = {
            status: res.status,
            success: res.status === 200 || res.status === 201
        };
        // console.log(res);
        if (res.data && res.data.data){
            response.data = res.data.data || {}
        } else {
            response.data = res.data;
        }
        return response;
    }

    //TOKEN MANAGEMENT
    isAuthExpired = () => {
        let threshold = this.getAuthExpiry() - (120 * 1000); //within 2 minutes;
        return Date.now() >= threshold; //current time is or is after 120 seconds before expiry time
    }
    getAuthExpiry = () => {
        return window.localStorage.getItem('authexpiry');
    }
    setAuthExpiry = (ts) => {
        window.localStorage.setItem('authexpiry', ts === undefined ? Date.now() + (3600 * 1000) : ts); //expires in 1 hour
    }
    getAuthToken = () => {
        return window.localStorage.getItem('authtoken');
    }
    getRefreshToken = () => {
        return window.localStorage.getItem('refreshtoken');
    }
    saveAuthToken = (token) => {
        window.localStorage.setItem('authtoken', token);
    }
    saveRefreshToken = (token) => {
        window.localStorage.setItem('refreshtoken', token);
    }
    deleteAuthToken = () => {
        window.localStorage.setItem('authtoken', '');
    }
    deleteRefreshToken = () => {
        window.localStorage.setItem('refreshtoken', '');
    }
}

const ApiHelper = new _helper();
export default ApiHelper;