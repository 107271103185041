import React, { Component } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ConditionalDOM } from './';

class BaseDropDown extends Component {
  constructor(props){
    super(props);
    let list = [];

    for(let item of props.items){
      list.push(item[props.titleProp]);
    }

    this.state = {
      dropList: list,
      isOpen: false,
      items: props.items,
      placeholder: props.placeholder
    };
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.placeholder !== this.props.placeholder){
      this.setState({placeholder: this.props.placeholder});
    }
  }

  //METHODS
  selectionChanged = (index) => {
    let item = this.props.items[index];

    this.setState({placeholder: item[this.props.titleProp]});
    this.props.selectionChanged(item);
  }
  toggle = () => {
    this.setState({isOpen: !this.state.isOpen});
  }

  //THE RENDER FUNCTION
  render() {
    return (
      <Dropdown isOpen={this.state.isOpen} toggle={this.toggle} style={{marginRight: '10px'}}>
        <DropdownToggle style={{backgroundColor: 'white', border: '1px solid black', borderRadius: '0px', color: 'black'}} caret>{this.state.placeholder}</DropdownToggle>
        <DropdownMenu className='reasonably-sized-menu'>
          <ConditionalDOM render={this.state.items}>
          {
            this.state.dropList.map((displayValue, i) => (
              <DropdownItem autoFocus={i === 0} key={'dropdownitem_'+displayValue} style={{textAlign: 'left'}} onClick={() => { this.selectionChanged(i); }}>{displayValue}</DropdownItem>
            ))
          }
          </ConditionalDOM>
          <ConditionalDOM render={!this.state.items}>
            <DropdownItem disabled>{this.props.emptyText}</DropdownItem>
          </ConditionalDOM>
        </DropdownMenu>
      </Dropdown>
    )
  }
}

export default BaseDropDown;
