const initialState = {
    list: []
};

const timeLogReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'timelog/SET_LIST':
            return {
                ...state,
                list: action.payload
            }
        case '*/CLEAR_STORE':
            return initialState;
        default:
            return state;
    }
}

export default timeLogReducer;