import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Row, Col } from 'reactstrap';

class LoadingWidget extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Modal isOpen={this.props.loading} backdrop={true} style={{width:'200px', marginTop: '0px', marginLeft: 'auto', marginRight: 'auto'}}>
        <Row style={{margin: '1.5em'}}>
          <Col sm='1'>
            <h4 style={{textAlign: 'center'}}>Loading</h4>
            <span style={{textAlign: 'center'}}>
              <div className="loader">
                <svg className="circular" viewBox="25 25 50 50">
                  <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
                </svg>
              </div>
            </span>
          </Col>
        </Row>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.modal.loading
})

export default connect(mapStateToProps)(LoadingWidget)
