import React, { Component } from 'react';
import Helpers from '../utilities/helpers';
import ConditionalDOM from './ConditionalDOM';

class BudgetItem extends Component {
  constructor(props){
    super(props);
    this.state = {
      title: '',
      budget: '',
      current: ''
    };
  }
  componentDidMount = () => {
    this.setState({
      title: this.props.project.title,
      budget: Helpers.FormatDollars(this.props.project.budget),
      current: Helpers.FormatDollars(this.props.project.current)
    });
    this.setProgress();
  }

  //HELPERS
  setProgress = () => {
    let diff = this.props.project.budget - this.props.project.current;
    let status = '';
    let backgroundColor = '';

    if (diff > 0) {
      status = Helpers.FormatDollars(diff) + ' under';
    } else if (diff < 0) {
      status = Helpers.FormatDollars(diff*-1) + ' over';
    } else {
      status = '$0 over';
    }

    this.setState({ status, backgroundColor });
  }
  getPieSlices = () => {
    if (this.props.project.budget === null || this.props.budget === 0){
      return { '--segment': '0', '--segment-color': 'purple'}
    }

    let percentage = this.props.project.current / this.props.project.budget * 100;

    if (percentage >= 100) {
      return { '--segment': 100, '--segment-color': '#ff3232' }
    } else if (percentage >= 75) {
      return { '--segment': percentage, '--segment-color': '#ffbf00' }
    } else {
      return { '--segment': percentage, '--segment-color': '#84e91f' }
    }
  }
  onClick = () => {
    if (!this.props.adminPie){
      return;
    }
    this.props.clickHandler(this.props.project);
  }

  //THE RENDER FUNCTION
  render() {
    return (
      <div id={this.props.project.idproject + '_proj_container'} className='pie-box' style={{cursor: this.props.adminPie ? 'pointer' : ''}} onClick={this.onClick}>
        <div id={this.props.project.idproject + '_pie'} className='pie' style={this.getPieSlices()}>
          <b className='pie-text'>{this.state.title}</b>
          <ConditionalDOM render={this.props.adminPie}>
            <p className='pie-text'>{Helpers.FormatDollars(this.state.current)}</p>
            <p className='pie-text'>{Helpers.FormatDollars(this.state.budget)}</p>
          </ConditionalDOM>
        </div>
      </div>
    )
  }
}

export default BudgetItem;