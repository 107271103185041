import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import * as actions from '../actions';
import { urls } from '.';
import ApiHelper from '../utilities/apiHelper';
import Helpers from '../utilities/helpers.js';
import { BaseButton, BaseDropDown, BaseInput, BudgetItem, ConditionalDOM, ReimbursableEditor, TimeLogEditor } from '../components';
import { Endpoints } from '../Constants';

class ManageProjects extends Component{
  constructor(props){
    super(props);
    this.state = {
      enableUpdate: false,
      filteredList: props.projects,
      isAddFormVisible: false,
      isUserRatesDialogOpen: false,
      pastProjects: [],
      projectHours: 0,
      reimbursables: [],
      selectedProject: {},
      selectedReimbursable: null,
      selectedTimeLog: null,
      selectedUser: {},
      temprate: {},
      timeLogs: [],
      viewHistory: false
    };
  }
  componentDidMount = async () => {
    if (ApiHelper.isAuthExpired()){
      let refreshed = await ApiHelper.tryRefresh();
      if (!refreshed){
        this.props.history.push(urls.login);
        return;
      }
    }
    this.ensureDataForPage();
  }

  //METHODS
  ensureDataForPage = async () => {
    if (this.props.projects && this.props.projects.length > 0){
      return;
    }
    this.props.showLoading();
    let response = await ApiHelper.get(Endpoints.Projects);
    if (response.data){
      this.props.setProjectList(response.data);
      this.setState({ filteredList: response.data });
    }
    this.props.closeLoading();
  }
  showProjectDetail = async (selectedProject) => {
    this.setState({ selectedProject, enableUpdate: false, isAddFormVisible: false, viewHistory: false, pastProjects: [] });

    let response = await ApiHelper.get(Endpoints.TimeLogs + '?project=' + selectedProject.idproject +'&all=true&asc=1');
    this.formatTimeLogs(response.data);

    response = await ApiHelper.get(Endpoints.Reimbursables + '?project=' + selectedProject.idproject +'&all=true');
    this.formatReimbursables(response.data);
  }
  formatTimeLogs = (list) => {
    let timeLogs = [];
    if (!list || list.length < 1){
      this.setState({timeLogs, projectHours: 0});
      return;
    }
    
    let projectSec = 0;

    for(let i in list){
      let item = list[i];
      let user = this.props.users.filter(el => el.iduser === item.iduser)[0];
      item.initials = user.initials;

      projectSec += item.duration;

      timeLogs.push(item);
    }
    this.setState({timeLogs, projectHours: Helpers.ConvertSecToHrs(projectSec)});
  }
  formatReimbursables = (list) => {
    let reimbursables = [];
    if (!list || list.length < 1){
      this.setState({reimbursables});
      return;
    }

    for(let item of list){
      let user = this.props.users.filter(el => el.iduser === item.iduser)[0];
      item.initials = user.initials;

      reimbursables.push(item);
    }
    this.setState({reimbursables});
  }
  closeDetail = () => {
    this.setState({ selectedProject: {}, enableUpdate: false, isAddFormVisible: false, timeLogs: [], reimbursables: [] });
  }
  showAddForm = () => {
    let proj = {
      idproject: 1,
      userrates: [ ]
    };
    this.setState({ isAddFormVisible: true, selectedProject: proj });
  }
  createProject = async () => {
    if (!this.state.selectedProject.title || this.state.selectedProject.title.length < 1){
      alert('Give the project a title.')
      return;
    }
    this.props.showLoading();
    let response = await ApiHelper.post(Endpoints.Projects, this.state.selectedProject);

    if (response.success){
      alert('The project was created.');
      this.props.setProjectList([]);
      this.ensureDataForPage();
      let proj = {...this.state.selectedProject, idproject: response.data.idproject, iscurrent: 1};
      this.setState({
        selectedProject: proj,
        isAddFormVisible: false
      });
    } else {
      alert('Failed to create the project.');
    }
    this.props.closeLoading();
  }
  updateProject = async () => {
    if (this.state.isAddFormVisible){
      return;
    }
    if (!this.state.selectedProject.title || this.state.selectedProject.title.length < 1){
      alert('Give the project a title.')
      return;
    }
    this.props.showLoading();
    let response = await ApiHelper.put(Endpoints.Projects, this.state.selectedProject);

    if (response.success){
      alert('Updated the project.');
      this.props.setProjectList([]);
      this.ensureDataForPage();
    } else {
      alert('Failed to update the project.');
    }
    this.props.closeLoading();
  }
  endProject = async () => {
    if (!window.confirm('Are you sure you want to end this project?')){
      return;
    }

    this.props.showLoading();
    let response = await ApiHelper.put(Endpoints.Projects, {...this.state.selectedProject, END_PROJECT: true});

    if (response.success){
      let list = this.props.projects.filter(el => el.idproject !== this.state.selectedProject.idproject)
      this.props.setProjectList(list);
      this.setState({filteredList: list});
      this.closeDetail();
    } else {
      alert('Failed to end the project.');
    }
    this.props.closeLoading();
  }
  unendProject = async () => {
    if (!window.confirm('Are you sure you want to bring back this project?')){
      return;
    }

    this.props.showLoading();
    let response = await ApiHelper.put(Endpoints.Projects, {...this.state.selectedProject, END_PROJECT: false});

    if (response.success){
      this.props.setProjectList([]);
      this.closeDetail();
      this.ensureDataForPage();
    } else {
      alert('Failed to bring back the project.');
    }
    this.props.closeLoading();
  }
  toggleUserRatesDialog = () => {
    this.setState({ isUserRatesDialogOpen: !this.state.isUserRatesDialogOpen, temprate: {} });
  }
  saveUserRatesClicked = () => {
    if (!this.state.temprate || !this.state.temprate.iduser || this.state.temprate.rate <= 0){
      alert('Invalid user rate');
      return;
    }
    let list = this.state.selectedProject.userrates;
    list.push(this.state.temprate);
    this.toggleUserRatesDialog();
    this.setState({ 
      selectedProject: {...this.state.selectedProject, userrates: list},
      temprate: {}
    });
    this.updateProject()
  }
  removeUserRate = (item) => {
    let list = this.state.selectedProject.userrates.filter(el => el.iduser !== item.iduser);
    let selectedProject = this.state.selectedProject;
    selectedProject.userrates = list;
    
    this.updateProject();
  }
  viewHistoryClicked = async () => {
    this.props.showLoading();
    let response = await ApiHelper.get(Endpoints.Projects + '?current=false');
    this.setState({viewHistory: true, pastProjects: response.data || []});
    this.props.closeLoading();
  }
  timeLogClicked = (selectedTimeLog) => {
    if (!selectedTimeLog || !selectedTimeLog.idtimelog){
      return;
    }

    this.setState({ selectedTimeLog });
  }
  unselectTimeLog = () => {
    this.setState({selectedTimeLog: null});
  }
  reimbursableClicked = (selectedReimbursable) => {
    if (!selectedReimbursable || !selectedReimbursable.idreimbursable){
      return;
    }

    this.setState({ selectedReimbursable });
  }
  unselectReimbursable = () => {
    this.setState({selectedReimbursable: null});
  }
  reloadList = () => {
    this.setState({selectedReimbursable: null, selectedTimeLog: null});
    this.showProjectDetail(this.state.selectedProject);
  }

  //EVENT HANDLERS
  onchange = (e) => {
    if (!e) { return; }
    this.setState({ [e.target.name]: e.target.value });
  }
  handleUpdates = (name, value) => {
    this.setState((prevState) => {
      let proj = {...prevState.selectedProject};
      proj[name] = value;

      return { selectedProject: proj }
    });
  }
  handleUpdates_userrate = (name, value) => {
    this.setState((prevState) => {
      let temprate = prevState.temprate;
      temprate.rate = value;

      return { temprate }
    });
  }
  selectedUserChanged = (user) => {
    let temprate = {
      iduser: user.iduser,
      userfullname: user.fullname,
      rate: ''
    };
    this.setState({ temprate });
  }

  //RENDER
  render() {
    return (
      <div className='content-view'>
        <div className='centered-container'>
          {/* MAIN VIEW */}
          <ConditionalDOM render={!this.state.selectedProject.idproject}>
            <div className='content-card singular-card' style={{width: '1000px'}}>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <p className='page-header'>Manage Projects</p>
                {/* <button onClick={this.showAddForm} className='add-button'>Add</button> */}
                <button onClick={this.showAddForm} className='add-button'>Add</button>
              </div>
              <Row>
                <Col xs='12'>
                  <p className='italic-text'>{this.props.projects.length > 0 ? 'Select a project below to view details' : 'There are currently no active projects.'}</p>
                </Col>
              </Row>
              <div className='centered-row'>
              {
                this.state.filteredList.map((item, i) => (
                  <BudgetItem key={'project'+item.idproject} project={item} clickHandler={this.showProjectDetail} adminPie={this.props.user.manageprojects}/>
                ))
              }
              </div>
              <ConditionalDOM render={!this.state.viewHistory}>
                <div style={{borderTop: '1px solid grey', marginTop: '20px', paddingTop: '5px', textAlign: 'center'}}>
                  <BaseButton content='View History' classes='reasonably-sized-button' onClick={this.viewHistoryClicked}/>
                </div>
              </ConditionalDOM>
            </div>

            <ConditionalDOM render={this.state.viewHistory}>
              <div className='content-card singular-card' style={{width: '1000px', marginBottom: '3em'}}>
                <p className='page-header'>Past Projects</p>
                <Row>
                  <Col xs='12'>
                    <p className='italic-text'>{this.state.pastProjects.length > 0 ? 'Select a project below to view details' : 'There are currently no inactive projects.'}</p>
                  </Col>
                </Row>
                <div className='centered-row'>
                {
                  this.state.pastProjects.map((item, i) => (
                    <BudgetItem key={'project'+item.idproject} project={item} clickHandler={this.showProjectDetail} adminPie={this.props.user.manageprojects}/>
                  ))
                }
                </div>
              </div>
            </ConditionalDOM>
          </ConditionalDOM>

          {/* DETAIL VIEW */}
          <ConditionalDOM render={this.state.selectedProject && this.state.selectedProject.idproject}>
            <div>
              <div className='content-card singular-card' style={{width: '900px'}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                  <p className='page-header temp-offset-for-input'>{this.state.isAddFormVisible ? 'Create Project' : 'Project Details'}</p>
                  <button onClick={this.closeDetail} className='close-detail-button'>{this.state.isAddFormVisible ? 'Cancel' : 'Close'}</button>
                </div>
                <Row>
                  <Col xs='6'>
                    <BaseInput name='title' startingValue={this.state.selectedProject.title} changeHandler={this.handleUpdates} label='Title' type='text' required/>
                  </Col>
                  <Col xs='6'>
                    <BaseInput name='budget' startingValue={Helpers.FormatDollars(this.state.selectedProject.budget)} changeHandler={this.handleUpdates} label='Budget' type='text'/>
                  </Col>
                </Row>
                <Row>
                  <Col xs='6'>
                    <BaseInput name='surcharge' startingValue={this.state.selectedProject.surcharge} changeHandler={this.handleUpdates} label='Surcharge' type='text'/>
                  </Col>
                  <Col xs='6'>
                    <BaseInput name='previoustotal' startingValue={Helpers.FormatDollars(this.state.selectedProject.previoustotal)} changeHandler={this.handleUpdates} label='Previous Total' type='text'/>
                  </Col>
                </Row>
                <Row>
                  <Col xs='12'>
                    <BaseInput name='notes' startingValue={this.state.selectedProject.notes} changeHandler={this.handleUpdates} label='Notes' type='textarea'/>
                  </Col>
                </Row>
                <Row>
                  <Col xs='12'>
                    <p className='dialog-header'style={{marginTop: '1em', marginBottom: '-1em'}}>Billing Details</p>
                  </Col>
                </Row>
                <Row>
                  <Col xs='6'>
                    <BaseInput name='billingcontact' startingValue={this.state.selectedProject.billingcontact} changeHandler={this.handleUpdates} label='Name' type='text'/>
                  </Col>
                  <Col xs='6'>
                    <BaseInput name='contactorganization' startingValue={this.state.selectedProject.contactorganization} changeHandler={this.handleUpdates} label='Company' type='text'/>
                  </Col>
                </Row>
                <Row>
                  <Col xs='6'>
                    <BaseInput name='contactemail' startingValue={this.state.selectedProject.contactemail} changeHandler={this.handleUpdates} label='Email' type='text'/>
                  </Col>
                  <Col xs='6'>
                    <BaseInput name='contactphone' startingValue={this.state.selectedProject.contactphone} changeHandler={this.handleUpdates} label='Phone' type='text'/>
                  </Col>
                </Row>
                <Row>
                  <Col xs='6'>
                    <BaseInput name='billingaddress' startingValue={this.state.selectedProject.billingaddress} changeHandler={this.handleUpdates} label='Address' type='textarea'/>
                  </Col>
                  <Col xs='6'>
                  </Col>
                </Row>
                <Row>
                  <Col xs='12'>
                    {/* ACTIONS */}
                    <ConditionalDOM render={this.state.isAddFormVisible}>
                      <div style={{marginTop: '4em'}}>
                        <BaseButton content='Create Project' onClick={this.createProject}/>
                      </div> 
                    </ConditionalDOM>
                    <ConditionalDOM render={!this.state.isAddFormVisible}>
                      <ConditionalDOM render={this.state.selectedProject.iscurrent === 1}>
                        <Row style={{marginTop: '4em'}}>
                          <Col xs='6'>
                            <BaseButton content='Save Edits' onClick={this.updateProject}/>
                          </Col>
                          <Col xs='6'>
                            <BaseButton content='End Project' classes='delete-button' onClick={this.endProject}/>
                          </Col>
                        </Row>
                      </ConditionalDOM>
                      <ConditionalDOM render={this.state.selectedProject.iscurrent === 0}>
                        <div style={{marginTop: '4em'}}>
                          <BaseButton content='Re-open Project' onClick={this.unendProject}/>
                        </div> 
                      </ConditionalDOM>
                    </ConditionalDOM>
                  </Col>
                </Row>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '2em'}}>
                  <p className='page-header'>User Rates</p>
                  <button className='add-button' onClick={this.toggleUserRatesDialog}>Add</button>
                </div>
                {
                  this.state.selectedProject.idproject && this.state.selectedProject.userrates && this.state.selectedProject.userrates.length > 0 ? 
                    this.state.selectedProject.userrates.map((item, i) => (
                      <div id={'user_'+i} key={'user'+item.userfullname} className='item-container' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <p className='temp-data-label'>{item.userfullname}: ${item.rate}/hr</p>
                        <button onClick={() => this.removeUserRate(item)} className='close-detail-button' style={{margin: '0px 0px 0px 10px'}}>X</button>
                      </div>
                    ))
                  : 
                  <i>There are no custom user rates for this project.</i>
                }
              </div>

              {/* TIME LOGS CARD */}
              <ConditionalDOM render={!this.state.isAddFormVisible}> 
                <div className='content-card singular-card' style={{width: '900px'}}>
                  <p className='page-header'>Time Logs</p>

                  <ConditionalDOM render={this.state.timeLogs.length}>
                    <p style={{marginBottom: '0.5em'}}>Total Hours: {this.state.projectHours}</p>
                    <div className='grid-row'>
                      <div className='grid-cell' style={{width: '80px'}}><p className='grid-header'>Initials</p></div>
                      <div className='grid-cell' style={{width: '150px'}}><p className='grid-header'>Date</p></div>
                      <div className='grid-cell' style={{width: '120px'}}><p className='grid-header'>Hours</p></div>
                      <div className='grid-cell' style={{width: '500px'}}><p className='grid-header'>Description</p></div>
                    </div>
                    {
                    this.state.timeLogs.map((item, i) => (
                      <div key={'item'+item.idtimelog} className='grid-row item-hover' onClick={() => this.timeLogClicked(item)}>
                        <div className='grid-cell' style={{width: '80px'}}><p className='grid-text'>{item.initials}</p></div>
                        <div className='grid-cell' style={{width: '150px'}}><p className='grid-text'>{item.friendlydate}</p></div>
                        <div className='grid-cell' style={{width: '120px'}}><p className='grid-text'>{Helpers.ConvertSecToHrs(item.duration)}</p></div>
                        <div className='grid-cell' style={{width: '500px'}}><p className='grid-text'>{item.notes}</p></div>
                      </div>
                    ))
                    }
                    <div style={{borderTop: '1px solid #000000', height: '10px'}}></div>
                  </ConditionalDOM>
                  <ConditionalDOM render={!this.state.timeLogs.length}>
                    <i>There are no time logs for this project.</i>
                  </ConditionalDOM>
                </div>
              </ConditionalDOM>

              {/* REIMBURSABLES CARD */}
              <ConditionalDOM render={!this.state.isAddFormVisible}> 
                <div className='content-card singular-card' style={{width: '900px', marginBottom: '50px'}}>
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <p className='page-header'>Reimbursables</p>
                  </div>

                  <ConditionalDOM render={this.state.reimbursables.length}>
                    <div className='grid-row'>
                      <div className='grid-cell' style={{width: '80px'}}><p className='grid-header'>Initials</p></div>
                      <div className='grid-cell' style={{width: '110px'}}><p className='grid-header'>Date</p></div>
                      <div className='grid-cell' style={{width: '80px'}}><p className='grid-header'>Amount</p></div>
                      <div className='grid-cell' style={{width: '80px'}}><p className='grid-header'>Link</p></div>
                      <div className='grid-cell' style={{width: '500px'}}><p className='grid-header'>Description</p></div>
                    </div>
                    {
                    this.state.reimbursables.map((item, i) => (
                      <div key={'r-item'+item.idreimbursable} className='grid-row item-hover' onClick={() => this.reimbursableClicked(item)}>
                        <div className='grid-cell' style={{width: '80px'}}><p className='grid-text'>{item.initials}</p></div>
                        <div className='grid-cell' style={{width: '110px'}}><p className='grid-text'>{item.friendlydate}</p></div>
                        <div className='grid-cell' style={{width: '80px'}}><p className='grid-text'>{Helpers.FormatDollars(item.amount)}</p></div>
                        <div className='grid-cell' style={{width: '80px'}} onClick={e => e.stopPropagation()}><p className='grid-text'><a href={item.imageurl} target='_blank' rel='noopener noreferrer' >View</a></p></div>
                        <div className='grid-cell' style={{width: '500px'}}><p className='grid-text'>{item.description}</p></div>
                      </div>
                    ))
                    }
                    <div style={{borderTop: '1px solid #000000', height: '10px'}}></div>
                  </ConditionalDOM>
                  <ConditionalDOM render={!this.state.reimbursables.length}>
                    <i>There are no reimbursables for this project.</i>
                  </ConditionalDOM>
                </div>
              </ConditionalDOM>
            </div>
          </ConditionalDOM>
        </div>

        {/* EDIT USER RATES */}
        <Modal isOpen={this.state.isUserRatesDialogOpen} backdrop={true} style={{maxWidth: '95vw', width: '550px'}}>
          <ModalBody>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <p className='dialog-header'>Add Custom User Rate</p>
              <button className='fa fa-window-close' onClick={this.toggleUserRatesDialog} style={{color: 'red', fontSize: '27px', backgroundColor: 'transparent', border: 'none', boxShadow: 'none', outline: 'none'}}/>
            </div>
            <p>Custom rates can be added for any user. This rate will be used when calculating budget progress for this project only. A user's base rate will be used if none is set for them on this project.</p>
            {
              this.state.temprate && this.state.temprate.iduser ? 
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center'}}>
                  <p className='temp-data-label' style={{marginRight: '10px'}}>{this.state.temprate.userfullname + ':'}</p>
                  <BaseInput changeHandler={this.handleUpdates_userrate} type='text' required/>
                </div>
              :
              <BaseDropDown items={this.props.users} 
                emptyText='No Users to Show' 
                placeholder={this.state.selectedUser.fullname ? this.state.selectedUser.fullname : 'Select a User'} 
                titleProp='fullname'
                selectionChanged={this.selectedUserChanged}/>
            }
            <div style={{marginBottom: '10px'}}></div>
            <Row>
              <Col xs='12'>
                <BaseButton content='Save' onClick={this.saveUserRatesClicked}/>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        {/* EDITORS */}
        <ReimbursableEditor reimbursable={this.state.selectedReimbursable} unselect={this.unselectReimbursable} reloadCb={this.reloadList}/>
        <TimeLogEditor timeLog={this.state.selectedTimeLog} unselect={this.unselectTimeLog} reloadCb={this.reloadList}/>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.session,
    projects: state.projects.list,
    users: state.user.list
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setUserSession: (obj) => dispatch(actions.setUserSession(obj)),
    logout: () => dispatch(actions.logout()),
    showLoading: () => dispatch(actions.showLoading()),
    closeLoading: () => dispatch(actions.closeLoading()),
    setProjectList: (arr) => dispatch(actions.setProjectList(arr))
  }
}
  
ManageProjects.propTypes = { 
  user: PropTypes.object.isRequired
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ManageProjects);