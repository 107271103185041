import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ConditionalDOM } from './';
import Helpers from '../utilities/helpers';

class TimeLog extends Component {
  constructor(props){
    super(props);

    let startSec = 0;
    if (props.timeLog.duration && props.timeLog.duration > 0){
      if (props.timeLog.state === 'paused'){
        startSec = props.timeLog.duration;
      } else if (props.timeLog.state === 'timer') {
        let modified = new Date(props.timeLog.lastmodified);
        startSec = props.timeLog.duration + Math.floor((new Date() - modified) / 1000);
      }
    } else {
      let created = new Date(props.timeLog.created);
      startSec = Math.floor((new Date() - created) / 1000);
    }

    this.state = {
      showSeparator: props.showDayTotal && props.timeLog.dayTotal,
      timeLog: props.timeLog,
      timeText: Helpers.ConvertSecToHrs(props.timeLog.duration),
      totalSeconds: startSec
    };

    if (props.timeLog.state === 'timer'){
      this.timer = setInterval(this.setTime, 1000);
    }
  }
  componentWillUnmount = () => {
    clearInterval(this.timer);
  }

  //METHODS
  setTime = () => {
    this.setState((prevState) => {
      let totalSeconds = prevState.totalSeconds + 1;
      let timeText = Helpers.FormatTimeText(totalSeconds);
      return { totalSeconds, timeText }
    });
  }
  clickHandler = () => {
    if (!this.props.clickHandler){
      return;
    }
    this.props.clickHandler(this.state.timeLog.idtimelog);
  }

  //THE RENDER FUNCTION
  render() {
    return (
      <div 
          className={'grid-row' + (Helpers.IsToday(this.state.timeLog.date) ? ' timer-highlight' : '') + (this.props.clickHandler ? ' item-hover' : '')} 
          style={this.state.showSeparator ? {marginBottom: '15px'} : null}
          onClick={this.clickHandler}>
        <ConditionalDOM render={this.props.userInitials}>
          <div className='grid-cell' style={{width: '100px', borderBottom: this.state.showSeparator ? '1px solid #000000' : null}}>
            <p className='grid-text'>{this.props.userInitials}</p>
          </div>
        </ConditionalDOM>
        <div className='grid-cell' style={{width: '100px', borderBottom: this.state.showSeparator ? '1px solid #000000' : null}}>
          <p className='grid-text'>{this.state.timeLog.friendlydate}</p>
        </div>
        <div className='grid-cell' style={{width: '160px', borderBottom: this.state.showSeparator ? '1px solid #000000' : null, overflow: 'atuo'}}>
          <p className='grid-text'>{this.state.timeLog.project.title}</p>
        </div>
        <div className='grid-cell' style={{width: '80px', borderBottom: this.state.showSeparator ? '1px solid #000000' : null}}>
          <p className='grid-text'>{this.state.timeText}</p>
        </div>
        <div className='grid-cell' style={{width: '450px', borderBottom: this.state.showSeparator ? '1px solid #000000' : null}}>
          <p className='grid-text'>{this.state.timeLog.notes}</p>
        </div>
        <ConditionalDOM render={this.props.showDayTotal}>
          <div className='grid-cell ghost-cell' style={{width: '60px'}}>
            <p className='grid-text'>{this.state.timeLog.dayTotal}</p>
          </div>
        </ConditionalDOM>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.user.list
  }
}
const mapDispatchToProps = (dispatch) => {
  return { }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(TimeLog);