import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import { BaseButton, BaseDropDown, BaseInput } from '.';
import ApiHelper from '../utilities/apiHelper';
import { Endpoints } from '../Constants';
import ConditionalDOM from './ConditionalDOM';

class ReimbursableEditor extends Component {
  constructor(props){
    super(props);
    this.state = {
      isOpen: false,
      paymentMethods: [{name: 'Check'}, {name: 'Credit Card'}],
      reimbursable: null,
      selectedPaymentMethod: {},
    };
  }
  componentDidUpdate = (prevProps) => {
    // # TEST to accommodate timers on home page
    if (this.state.isOpen || !this.props.reimbursable){
      return;
    }
    this.setState({ 
      reimbursable: this.props.reimbursable, 
      isOpen: true,
      selectedPaymentMethod: this.props.reimbursable.paymentmethod ? this.state.paymentMethods.filter(el => el.name === this.props.reimbursable.paymentmethod)[0] : {}
    });
  }

  //METHODS
  selectedProjectChanged = (selection) => {
    let reimbursable = {...this.state.reimbursable, project: selection};
    this.setState({reimbursable});
  }
  selectedPaymentMethodChanged = (selectedPaymentMethod) => {
    this.setState({selectedPaymentMethod});
  }
  editClicked = async () => {
    if (!window.confirm('Are you sure that you want to modify this reimbursable?')){
      return;
    }
    let reimbursable = this.state.reimbursable;
    if (reimbursable.project){
      reimbursable.idproject = reimbursable.project.idproject;
    } else {
      alert('Invalid project? Check console for component state.');
      console.log(this.state);
      return;
    }

    if (this.state.selectedPaymentMethod){
      reimbursable.paymentmethod = this.state.selectedPaymentMethod.name;
    }
    let response = await ApiHelper.put(Endpoints.Reimbursables, reimbursable);

    if (!response.success){
      alert('Something went wrong while trying to edit this reimbursable.');
      return;
    }
    if (this.props.reloadCb){
      this.props.reloadCb();
      this.close();
    }
  }
  deleteClicked = async () => {
    if (!window.confirm('Are you sure that you want to delete this reimbursable?')){
      return;
    }
    let response = await ApiHelper.delete(Endpoints.Reimbursables + '?id=' + this.state.reimbursable.idreimbursable);
    
    if (!response.success){
      alert('Something went wrong while trying to delete this reimbursable.');
      return;
    }
    if (this.props.reloadCb){
      this.props.reloadCb();
      this.close();
    }
  }
  close = () => {
    if (!this.state.selectedPaymentMethod.name){
      alert('Please select a payment method.');
      return;
    }
    this.setState({ isOpen: false, reimbursable: null });
    this.props.unselect();
  }

  //EVENTS
  handleUpdates = (name, value) => {
    this.setState((prevState) => {
      let reimbursable = prevState.reimbursable;
      reimbursable[name] = value;

      return { reimbursable }
    });
  }

  //THE RENDER FUNCTION
  render() {
    if (!this.props.reimbursable || !this.state.isOpen){
      return null;
    }
    return (
      <Modal isOpen={this.state.isOpen} backdrop={true} style={{maxWidth: '95vw', width: '550px'}}>
        <ModalBody>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <p className='dialog-header'>Edit Reimbursable</p>
            <button className='fa fa-window-close' onClick={this.close} style={{color: 'red', fontSize: '27px', backgroundColor: 'transparent', border: 'none', boxShadow: 'none', outline: 'none'}}/>
          </div>
          {/* <p>Subtext, instructions..</p> */}

          {/* drop down */}
          <div style={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-between'}}>
            <div style={{margin: '0px'}}>
              <BaseDropDown items={this.props.projects} 
                emptyText='No Projects to Show' 
                placeholder={this.state.reimbursable.project && this.state.reimbursable.project.title ? this.state.reimbursable.project.title : 'Select a project'} 
                titleProp='title' 
                selectionChanged={this.selectedProjectChanged}/>
            </div>
          </div>
          <div style={{margin: '20px 0px 0px 0px'}}>
            <BaseDropDown items={this.state.paymentMethods} 
              emptyText='No Payment Methods' 
              placeholder={this.state.selectedPaymentMethod.name ? this.state.selectedPaymentMethod.name : 'Payment Method'} 
              titleProp='name' 
              selectionChanged={this.selectedPaymentMethodChanged}/>
          </div>

          <Row>
            <Col xs='12' md='6'>
              <BaseInput name='date' startingValue={this.state.reimbursable.date} changeHandler={this.handleUpdates} label='Date' type='date' required/>
            </Col>
            <Col xs='12' md='6'>
              <BaseInput name='amount' startingValue={this.state.reimbursable.amount} changeHandler={this.handleUpdates} label='Amount' type='text'/>
            </Col>
          </Row>
          <Row>
            <Col xs='12'>
              <BaseInput name='description' classes='reimbursable-description-input' startingValue={this.state.reimbursable.description} changeHandler={this.handleUpdates} label='Description' type='textarea'/>
            </Col>
          </Row>
          <ConditionalDOM render={!this.props.addMode}>
            <Row style={{padding: '0px', margin: '1em 0px'}}>
              <Col xs='12'>
                <i style={{fontSize: '16px'}}>
                  <a href={this.state.reimbursable.imageurl} target='_blank' rel='noopener noreferrer'>View</a> the file.</i>
              </Col>
            </Row>
          </ConditionalDOM>
          <ConditionalDOM render={!this.props.addMode}>
            <Row>
              <Col xs='12' lg='6'>
                <BaseButton content='Save Edits' onClick={this.editClicked}/>
              </Col>
              <Col xs='12' lg='6'>
                <BaseButton content='Delete Reimbursable' classes='delete-button' onClick={this.deleteClicked}/>
              </Col>
            </Row>
          </ConditionalDOM>
          <ConditionalDOM render={this.props.addMode}>
            <Row>
              <Col xs='12'>
                <BaseButton content='Close' onClick={this.close}/>
              </Col>
            </Row>
          </ConditionalDOM>
        </ModalBody>
      </Modal>    
    )
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.projects.list
  }
}
const mapDispatchToProps = (dispatch) => {
  return { }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReimbursableEditor);
