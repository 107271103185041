import React, { Component } from 'react';
import { Input, Label, UncontrolledTooltip } from 'reactstrap';
import Helpers from '../utilities/helpers';

class BaseInput extends Component {
  constructor(props){
    super(props);

    this.state = {
      disabled: props.disabled || false,
      active: this.getInitialActive(props),
      inputValue: props.startingValue || '',
      hasError: false,
      changeHandler: props.changeHandler
    };
  }
  componentDidMount = () => {
    if (this.props.autoFocus === true){
      this.gotFocus();
    }
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.startingValue !== this.props.startingValue){
      this.setState({inputValue: this.props.startingValue, active: this.getInitialActive(this.props)});
      return;
    }
    if (this.state.active){
      return;
    }
    if (this.props.value && this.props.value.toString().length > 0){
      this.setState({active: true});
    }
  }

  //EVENTS
  gotFocus = () => {
    this.setState({active: true});
  }
  lostFocus = (e) => {
    this.setState({active: this.state.inputValue.toString().length > 0 || this.props.required || this.props.type === 'date'});
    this.validate();
  }
  onChange = (e) => {
    this.state.changeHandler(this.props.name, e.target.value);
    this.setState({
      inputValue: e.target.value, 
      active: e.target.value.length > 0
    });
  }

  //HELPERS
  getInitialActive = (props) => {
    if (props.startingValue && props.startingValue.toString().length > 0){
      return true;
    }
    if (props.type === 'date'){
      return true;
    }

    return false;
  }
  setErrState = (hasError) => {
    this.setState({hasError});
  }
  validate = () => {
    if (this.state.inputValue.length < 1 && this.props.required){
      this.setErrState(true);
      return;
    }

    if (this.props.type === 'password'){
      this.setErrState(!Helpers.IsValidPassword(this.state.inputValue));
      return;
    }
    if (this.props.type === 'email'){
      this.setErrState(!Helpers.IsValidEmail(this.state.inputValue));
      return;
    }

    this.setErrState(false);
  }

  //THE RENDER FUNCTION
  render() {
    return (
      <div style={this.props.type === 'textarea' ? textAreaContainer : baseContainer}>
        {
          this.props.label && this.props.label.length > 0 ?
            <Label for='input' className={this.state.active ? 'field-active' : ''}>{this.props.label}</Label>
            : null
        }
        <Input name='input' 
          id={'input_' + this.props.name}
          value={this.state.inputValue}
          type={this.props.type}
          className={(this.state.hasError ? 'input-err ' : ' ') + (this.props.type === 'textarea' ? 'base-textarea ' : ' ') + this.props.classes} 
          style={baseStyle} 
          disabled={this.props.disabled || false}
          autoComplete={this.props.type}
          onFocus={this.gotFocus} 
          onBlur={this.lostFocus} 
          onChange={this.onChange}/>
        {
          this.props.tooltip ? 
            <UncontrolledTooltip target={'input_' + this.props.name} placement='top'>{this.props.tooltip}</UncontrolledTooltip>
            : null
        }
      </div>
    )
  }
}

const baseContainer = {
  textAlign: 'left',
  height: '70px',
  verticalAlign: 'top'
};
const textAreaContainer = {
  textAlign: 'left',
  minHeight: '5em',
  verticalAlign: 'top'
}
const baseStyle = {
  backgroundColor: '#f8f8f8',
  boxShadow: 'none', 
  border: 'none', 
  borderBottom: '1px solid darkblue',
  borderRadius: '0px',
  outline: 'none',
  height: '50px',
  fontSize: '18px'
}

export default BaseInput;