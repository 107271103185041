class _Logger {
    constructor (print = false) {
        if (print === true){
            console.log('LOGGER WAS INITIALIZED WITH PRINTING ENABLED');
        }
        this.printLogs = print
    }

    _printlogs = false;

    // * LOGGING METHODS
    LogMessage(msg, force){
        if (!msg){
            this.LogError('Logger was given a blank message to log...', false);
            return;
        }
        if (force === true || this.printLogs === true){
            console.log(msg);
        }
    }
    LogWarning(msg, force){
        if (!msg){
            this.LogError('Logger was given a blank message to warn...', false);
            return;
        }
        if (force === true || this.printLogs === true){
            console.warn(msg);
        }
    }
    LogError(err, force){
        if (!err){
            this.LogError('Logger was given a blank error to log...', false);
            return;
        }
        if (force === true || this.printLogs === true){
            console.error(err);
        }
    }
    LogApiError(err, force){
        if (!err){
            this.LogError('Logger was given a blank error to log...', false);
            return;
        }
        if (force === true || this.printLogs === true){
            console.error('Received an error from the beloved API helper.');
            console.error(err);
        }
    }

    // * CONFIG METHODS
    SetPrinting(print){
        if (print !== Boolean){
            return;
        }
        this.printLogs = print;
    }
}
const Logger = new _Logger(false);
export default Logger;