import Login from './Login';
import Home from './Home';
import Error from './Error';
import ForgotPassword from './ForgotPassword';
import TimeLogs from './TimeLogs';
import Reimbursables from './Reimbursables';
import History from './History';
import ManageProjects from './ManageProjects';
import ManageUsers from './ManageUsers';
import ManageInvoices from './ManageInvoices';
import OAuthComplete from './OAuthComplete';
import Profile from './Profile';
import Reports from './Reports';

export { Login };
export { Home };
export { Error };
export { ForgotPassword };
export { TimeLogs };
export { Reimbursables };
export { History };
export { ManageProjects };
export { ManageUsers };
export { ManageInvoices };
export { OAuthComplete };
export { Profile };
export { Reports };

export const urls = {
    login: '/',
    home: '/home',
    error: '/error',
    forgotPassword: '/forgot-password',
    timeLogs: '/time-logs',
    reimbursables: '/reimbursables',
    history: '/history',
    manageProjects: '/projects',
    manageUsers: '/users',
    manageInvoices: '/invoices',
    profile: '/profile',
    reports: '/reports',

    oAuthComplete: '/oauth-complete'
}