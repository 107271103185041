import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../actions';
import { urls } from '.';
import ApiHelper from '../utilities/apiHelper';
import { Endpoints } from '../Constants';
import { BaseButton, BaseDropDown, BaseInput, ConditionalDOM, TimeLog, MultiSelectDropDown, TimeLogEditor } from '../components';
import Helpers from '../utilities/helpers';
import XlsxPopulate from 'xlsx-populate';

const fileExtension = '.xlsx';

class Reports extends Component{
  constructor(props){
    super(props);

    this.state = {
      filteredList: [],
      reportHours: '',
      selectedProject: {},
      selectedTimeLog: {},
      selectedUsers: [],
      sortEnd: '',
      sortStart: '',
      userHoursMap: {}
    };
  }
  componentDidMount = async () => {
    if (ApiHelper.isAuthExpired()){
      let refreshed = await ApiHelper.tryRefresh();
      if (!refreshed){
        this.props.history.push(urls.login);
        return;
      }
    }
    this.ensureDataForPage();
  }

  //METHODS
  ensureDataForPage = async () => {
    if (!this.props.projects || this.props.projects.length < 1){
      this.props.showLoading();
      let response = await ApiHelper.get(Endpoints.Projects);
      if (response.data){
        this.props.setProjectList(response.data);
      }
    }
    if (this.props.user.manageprojects && (!this.props.users || this.props.users.length < 1)){
      this.props.showLoading();
      let response = await ApiHelper.get(Endpoints.Users);
      if (response.data){
        this.props.setUserList(response.data);
      }
    }

    this.props.closeLoading();
  }
  selectedProjectChanged = async (selectedProject) => {
    this.setState({ selectedProject, showClearSort: true });
  }
  userSelectionsChanged = (list) => {
    this.setState({ selectedUsers: list, showClearSort: list.length > 0 || this.state.selectedProject.idproject });
  }
  clearSortOption = () => {
    // this.setState({
    //   selectedProject: {},
    //   selectedUsers: [],
    //   showClearSort: false,
    //   sortStart: '',
    //   sortEnd: '',
    //   filteredList: []
    // });
    window.location.reload();
  }
  getTimeLogsClicked = async () => {
    let params = '?current=false&asc=1';

    //handle project
    if (this.state.selectedProject.idproject){
      let id = this.state.selectedProject.idproject;
      params += '&project='+id;
    }

    //handle dates
    if (this.state.sortStart && this.state.sortStart.length > 1){
      params += '&start='+this.state.sortStart;
    }
    if (this.state.sortEnd && this.state.sortEnd.length > 1){
      params += '&end='+this.state.sortEnd;
    }

    //handle user(s)
    if (this.state.selectedUsers.length > 0){
      let ids = '';
      for(var user of this.state.selectedUsers){
        let obj = this.props.users.filter(el => el.fullname === user)[0];
        if (obj.iduser){
          ids += ',' + obj.iduser;
        }
      }
      params += '&userids='+ids.substring(1);
    } else if (this.props.user.manageprojects) {
      params += `&all=true`;
    } else {
      params += `&iduser=` + this.props.user.iduser;
    }

    this.props.showLoading();
    let response = await ApiHelper.get(Endpoints.TimeLogs + params);
    let filteredList = [];
    let reportSec = 0;
    let userHoursMap = {};

    if (response.data){
      filteredList = response.data;
      for(let log of filteredList){
        //add time log to total hours calculation
        reportSec += log.duration;

        //add time log to respective user's hours calculation
        let key = 'u'+log.iduser;
        if (userHoursMap[key]){
          userHoursMap[key] += log.duration;
        } else {
          userHoursMap[key] = log.duration;
        }
      }
    } else if (response.status === 404){
      alert('There were no Time Logs for the given filters.');
    } else {
      alert('Something went wrong while trying to fetch Time Logs. Please specify at least one filter.');
    }
    this.setState({
      filteredList, 
      userHoursMap,
      reportHours: Helpers.ConvertSecToHrs(reportSec)
    });
    this.props.closeLoading();
  }
  generateReport = () => {
    let list = [];
    let daySec = 0;

    //initials, date, hours, total (aka day total), client, description
    for(let i in this.state.filteredList){
      let log = this.state.filteredList[i];
      let user = this.getUser(log.iduser);

      if (i != 0 && log.date !== this.state.filteredList[i-1].date){
        list[i-1].Total = parseFloat(Helpers.ConvertSecToHrs(daySec));
        daySec = 0;
      }

      daySec += log.duration;

      let row = {
        "Initials": user.initials,
        "Date": log.friendlydate,
        "Hours": parseFloat(Helpers.ConvertSecToHrs(log.duration)),
        "Total": '',
        "Client": log.project.title,
        "Description": log.notes
      }
      list.push(row);

    }
    list[list.length - 1].Total = parseFloat(Helpers.ConvertSecToHrs(daySec));

    this.makeReportFile(list, "Report");
  }
  getUser = (id) => {
    return this.props.users.filter(el => el.iduser === id)[0];
  }
  makeReportFile = (data, title) => {
    try {
      //documentation: https://www.npmjs.com/package/xlsx-populate  
      XlsxPopulate.fromBlankAsync()
        .then(workbook => {
          //get the sheet
          let sheet = workbook.sheet('Sheet1');
          let r = 1;

          //set headers
          sheet.cell('A1').value('Initials');
          sheet.cell('B1').value('Date');
          sheet.cell('C1').value('Hours');
          sheet.cell('D1').value('Total');
          sheet.cell('E1').value('Client');
          sheet.cell('F1').value('Description');

          sheet.row(r++).style('bold', true);

          //set data
          for(let iota of data){
            sheet.cell('A' + r).value(iota.Initials);
            sheet.cell('B' + r).value(iota.Date);
            sheet.cell('C' + r).value(iota.Hours);
            sheet.cell('C' + r).style("numberFormat", "0.0");
            sheet.cell('D' + r).value(iota.Total);
            sheet.cell('D' + r).style("numberFormat", "0.0");

            sheet.cell('E' + r).style('wrapText', true);
            sheet.cell('E' + r).value(iota.Client);

            sheet.cell('F' + r).style('wrapText', true);
            sheet.cell('F' + r).value(iota.Description ? iota.Description.trim('\n') : '');

            sheet.row(r).style('verticalAlignment', 'center');

            r++;
          }

          //add total hours in the C column
          r++; //skip a line
          sheet.cell('C'+r).value('Total: ' + this.state.reportHours);

          //format columns
          sheet.column('A').style({ horizontalAlignment: 'center' });
          sheet.column('B').style({ horizontalAlignment: 'center' });
          sheet.column('C').style({ horizontalAlignment: 'center' });
          sheet.column('D').style({ horizontalAlignment: 'center' });
          sheet.column('E').style({ horizontalAlignment: 'center' });
          sheet.column('F').style({ horizontalAlignment: 'center' });
          
          sheet.column('E').width(25);
          sheet.column('F').width(55);
  
          //download the workbook
          workbook.outputAsync()
            .then(function (blob) {
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                  // If IE, you must uses a different method.
                  window.navigator.msSaveOrOpenBlob(blob, title + "_IE" + fileExtension);
              } else {
                  var url = window.URL.createObjectURL(blob);
                  var a = document.createElement("a");
                  document.body.appendChild(a);
                  a.href = url;
                  a.download = title + fileExtension;
                  a.click();
                  window.URL.revokeObjectURL(url);
                  document.body.removeChild(a);
              }
          });
        });
    } catch (error) {
      console.error(error);
    }
  }
  generateOverview = () => {
    let list = [];

    let projectBlocks = this.state.filteredList.sort((a, b) => {
      return Helpers.SortByThenBy(a.project.title, b.project.title) || Helpers.SortByThenBy(a.date, b.date);
    });


    //initials, date, hours, client, description
    for(let i in projectBlocks){
      let log = projectBlocks[i];
      let user = this.getUser(log.iduser);

      let row = {
        "UserID": user.iduser,
        "Initials": user.initials,
        "Date": log.friendlydate,
        "Hours": parseFloat(Helpers.ConvertSecToHrs(log.duration)),
        "Client": log.project.title,
        "Description": log.notes,
        "Duration": log.duration,
      }
      list.push(row);
    }

    this.makeOverviewFile(list, "Overview");
  }
  makeOverviewFile = (data, title) => {
    try {
      let stronggrey = 'CBCBCB';
      let weakgrey = 'EBEBEB';

      //documentation: https://www.npmjs.com/package/xlsx-populate  
      XlsxPopulate.fromBlankAsync()
        .then(workbook => {
          //get the sheet
          let sheet = workbook.sheet('Sheet1');
          let r = 1;

          //set headers
          sheet.cell('A1').value('Initials');
          sheet.cell('B1').value('Date');
          sheet.cell('C1').value('Hours');
          sheet.cell('D1').value('Client');
          sheet.cell('E1').value('Description');

          sheet.row(r++).style('bold', true);

          //set initial values to separate data into blocks
          let prevProject = data[0].Client;
          let projectTotal = 0;
          let usermap = {};
          let addUserHours = !data.every(x => x.UserID === data[0].UserID);

          //add data to sheet
          for(let iota of data){
            if (iota.Client !== prevProject){
              //add user totals to 'C' column
              if (addUserHours){
                for(let user of Object.getOwnPropertyNames(usermap)){
                  let id = parseInt(user.substring(1));
                  let initials = this.getUser(id).initials;
                  let hrs = Helpers.ConvertSecToHrs(usermap[user]);
  
                  sheet.cell('C' + r).value(initials + ': ' + hrs);
                  sheet.range('A' + r + ':E' + r).style('fill', weakgrey);
                  r++; //increment counter for next user or projectTotal
                }
                usermap = {};
              }

              //add projectTotal to 'C' column
              sheet.cell('C' + r).value('Total: ' + Helpers.ConvertSecToHrs(projectTotal));
              sheet.range('A' + r + ':E' + r).style('fill', stronggrey);

              r++; //skip a line
              r++; //set counter for next data row

              //reset vars for next proj
              prevProject = iota.Client;
              projectTotal = 0;
            }

            sheet.cell('A' + r).value(iota.Initials);
            sheet.cell('B' + r).value(iota.Date);
            sheet.cell('C' + r).value(iota.Hours);
            sheet.cell('C' + r).style("numberFormat", "0.0");

            sheet.cell('D' + r).style('wrapText', true);
            sheet.cell('D' + r).value(iota.Client);

            sheet.cell('E' + r).style('wrapText', true);
            sheet.cell('E' + r).value(iota.Description ? iota.Description.trim('\n') : '');

            sheet.row(r).style('verticalAlignment', 'center');

            //update running values
            projectTotal += iota.Duration;

            if (addUserHours){
              let key = 'u' + iota.UserID;
              if (usermap[key]){
                usermap[key] += iota.Duration;
              } else {
                usermap[key] = iota.Duration;
              }
            }

            //increment row counter
            r++;
          }

          //add totals for final proj (not caught in for loop)
          //r++; //skip a line
          sheet.cell('C' + r).value('Total: ' + Helpers.ConvertSecToHrs(projectTotal));
          sheet.range('A' + r + ':E' + r).style('fill', stronggrey);

          //format columns
          sheet.column('A').style({ horizontalAlignment: 'center' });
          sheet.column('B').style({ horizontalAlignment: 'center' });
          sheet.column('C').style({ horizontalAlignment: 'center' });
          sheet.column('D').style({ horizontalAlignment: 'center' });
          sheet.column('E').style({ horizontalAlignment: 'center' });
          
          sheet.column('D').width(25);
          sheet.column('E').width(55);
  
          //download the workbook
          workbook.outputAsync()
            .then(function (blob) {
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                  // If IE, you must uses a different method.
                  window.navigator.msSaveOrOpenBlob(blob, title + "_IE" + fileExtension);
              } else {
                  var url = window.URL.createObjectURL(blob);
                  var a = document.createElement("a");
                  document.body.appendChild(a);
                  a.href = url;
                  a.download = title + fileExtension;
                  a.click();
                  window.URL.revokeObjectURL(url);
                  document.body.removeChild(a);
              }
          });
        });
    } catch (error) {
      console.error(error);
    }
  }
  timeLogClicked = (id) => {
    if (!id){
      return;
    }
    let selectedTimeLog = this.state.filteredList.filter(el => el.idtimelog === id)[0];
    this.setState({ selectedTimeLog });
  }
  unselectTimeLog = () => {
    this.setState({selectedTimeLog: null});
  }
  reloadList = () => {
    this.setState({selectedTimeLog: null, filteredList: []});
    this.getTimeLogsClicked();
  }

  //EVENT HANDLERS
  onchange = (e) => {
    if (!e) { return; }
    this.setState({ [e.target.name]: e.target.value });
  }
  handleUpdates = (name, value) => {
    this.setState((prevState) => {
      prevState[name] = value;
      prevState.showClearSort = true;
      return { prevState }
    });
  }

  //RENDER
  render() {
    return (
      <div className='content-view'>
        <div className='centered-container'>
          <div className='content-card singular-card' style={{width: '900px'}}>
            <div>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <div>
                  <p className='page-header'>Reports</p>
                </div>
                <ConditionalDOM render={this.state.showClearSort}>
                  <button onClick={this.clearSortOption} className='close-detail-button'>Clear</button>
                </ConditionalDOM>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'left', margin: '1em 0px'}}>
                <BaseDropDown items={this.props.projects} 
                  emptyText='No Projects to Show' 
                  placeholder={this.state.selectedProject.title ? this.state.selectedProject.title : 'Select a Project'} 
                  titleProp='title' 
                  selectionChanged={this.selectedProjectChanged}/>
                <ConditionalDOM render={this.props.user.manageprojects}>
                  <MultiSelectDropDown items={this.props.users} 
                    emptyText='No Users to Show'
                    listUpdated={this.userSelectionsChanged}
                    placeholder={this.state.selectedUsers.length ? Helpers.Pluralize('user', this.state.selectedUsers.length, true) + ' selected' : 'Select a User'}
                    titleProp='fullname'/>
                </ConditionalDOM>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'left', margin: '-1em 0px 1em 0px'}}>
                <BaseInput name='sortStart' changeHandler={this.handleUpdates} label='Start Date' type='date'/>
                <div style={{width: '20px'}}/>
                <BaseInput name='sortEnd' changeHandler={this.handleUpdates} label='End Date' type='date'/>
              </div>
            </div>
            <div style={{textAlign: 'center'}}>
              <BaseButton content='Retrieve Time Logs' classes='reasonably-sized-button' onClick={this.getTimeLogsClicked}/>
            </div>
          </div>
          <ConditionalDOM render={this.state.filteredList.length > 0}>
            <div className='content-card singular-card' style={{width: '900px'}}>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '0px 0px 10px 0px'}}>
                <div>
                  <p className='page-header'>Results</p>
                  <p className='italic-text'>Total Hours: {this.state.reportHours}</p>
                  {
                    Object.getOwnPropertyNames(this.state.userHoursMap).map((userKey, i) => (
                      <p key={'userrate_' + userKey} className='italic-text'>
                        {this.getUser(parseInt(userKey.substring(1))).initials 
                        + ': ' 
                        + Helpers.ConvertSecToHrs(this.state.userHoursMap[userKey]) 
                        + ' hrs'}
                      </p>
                      )
                    )
                  }
                </div>
                <div className='report-options-wrapper'>
                    <BaseButton content='Generate Report' classes='report-button' onClick={this.generateReport}/>
                    <BaseButton content='Generate Overview' classes='report-button' onClick={this.generateOverview}/>
                </div>
              </div>
              <div className='grid-row'>
                <div className='grid-cell' style={{width: '100px'}}><p className='grid-header'>Initials</p></div>
                <div className='grid-cell' style={{width: '100px'}}><p className='grid-header'>Date</p></div>
                <div className='grid-cell' style={{width: '160px'}}><p className='grid-header'>Project</p></div>
                <div className='grid-cell' style={{width: '80px'}}><p className='grid-header'>Hours</p></div>
                <div className='grid-cell' style={{width: '450px'}}><p className='grid-header'>Description</p></div>
              </div>
              {
                this.state.filteredList.map((log, i) => (
                  <TimeLog key={'l' + log.idtimelog} userInitials={this.getUser(log.iduser).initials} timeLog={log} clickHandler={this.timeLogClicked}/>
                ))
              }
              <div style={{borderTop: '1px solid #000000', height: '10px'}}></div>
            </div>
          </ConditionalDOM>
        </div>

        {/* EDIT TIME LOG */}
        <TimeLogEditor timeLog={this.state.selectedTimeLog} showHours={this.state.selectedTimeLog && this.state.selectedTimeLog.iduser === this.props.user.iduser} unselect={this.unselectTimeLog} reloadCb={this.reloadList}/>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
    return {
      user: state.user.session,
      timelogs: state.timeLogs.list,
      projects: state.projects.list,
      users: state.user.list
    }
  }
const mapDispatchToProps = (dispatch) => {
  return {
    setUserSession: (obj) => dispatch(actions.setUserSession(obj)),
    logout: () => dispatch(actions.logout()),
    showLoading: () => dispatch(actions.showLoading()),
    closeLoading: () => dispatch(actions.closeLoading()),
    setTimeLogs: (arr) => dispatch(actions.setTimeLogList(arr)),
    setProjectList: (arr) => dispatch(actions.setProjectList(arr)),
    setUserList: (arr) => dispatch(actions.setUserList(arr))
  }
}
  
Reports.propTypes = { 
  user: PropTypes.object.isRequired
}
  
  export default connect(mapStateToProps, mapDispatchToProps)(Reports);