const initialState = {
    list: []
};

const reimbursableReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'reimbursable/SET_LIST':
            return {
                ...state,
                list: action.payload
            }
        case '*/CLEAR_STORE':
            return initialState;
        default:
            return state;
    }
}

export default reimbursableReducer;