import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import * as actions from '../actions';
import { urls } from '.';
import ApiHelper from '../utilities/apiHelper';
import Helpers from '../utilities/helpers.js';
import { BaseButton, BaseInput, ConditionalDOM } from '../components';
import { Endpoints } from '../Constants';

class Profile extends Component{
  constructor(props){
    super(props);
    this.state = {
      enableUpdate: false,
      errorMessage: '',
      firstname: props.user.firstname,
      isChangePwOpen: false,
      lastname: props.user.lastname,
      phonenumber: props.user.phonenumber,
      user: props.user
    };
  }
  componentDidMount = async () => {
    if (ApiHelper.isAuthExpired()){
      let refreshed = await ApiHelper.tryRefresh();
      if (!refreshed){
        this.props.history.push(urls.login);
        return;
      }
    }
  }

  //METHODS
  saveChangesClicked = async (e) => {
    e.preventDefault();
    if (!this.state.enableUpdate){
      alert('Please fill in the form!');
      return;
    }

    if (!this.state.firstname){
      alert('First Name is empty');
      return;
    }
    if (!this.state.lastname){
      alert('Last Name is empty');
      return;
    }
    if (!this.state.phonenumber){
      alert('Phone is empty, but not required');
    }

    let profile = {
      ...this.props.user,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      phonenumber: this.state.phonenumber
    }

    let response = await ApiHelper.put(Endpoints.Users , profile);

    if (response.success){
      alert('Your information was updated.');
      this.props.setUserSession(profile);
    } else {
      alert('Something went wrong while trying to update your information in the system.');
    }
  }
  submitNewPassword = async (e) => {
    e.preventDefault();

    if (this.state.newpw !== this.state.confirmnewpw){
      this.showErrorText('New Password and Confirmation field don\'t match.');
      return;
    }

    if (!Helpers.IsValidPassword(this.state.newpw)){
      this.showErrorText('Your new password must be 8 characters or more, and contain each of the following: One Number, One Uppercase Letter, One Lowercase Letter.');
      return;
    }
    
    this.props.showLoading();
    let response = await ApiHelper.put(Endpoints.ChangePw, {newpw: this.state.newpw});

    if (response.success){
      alert('Your password was updated. You will remain signed in, and receive a confirmation email momentarily.');
      this.toggleModal();
    } else {
      this.showErrorText('There was a problem while submitting your new password.');
    }
    this.props.closeLoading();
  }
  toggleModal = () => {
    this.setState({
      isChangePwOpen: !this.state.isChangePwOpen,
      errorMessage: '',
      newpw: '',
      confirmnewpw: ''
    });
  }
  showErrorText = (msg) => {
    this.setState({ errorMessage: msg });
  }
  downloadDesktopProgram = async () => {
    var url = "https://haas-cg-assets.s3-us-west-2.amazonaws.com/desktop-program/haastime-desktop-latest.zip";
    var link = document.createElement("a");
    link.download = 'Haas Time for Desktop.zip';
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  //EVENT HANDLERS
  onchange = (e) => {
    if (!e) { return; }
    this.setState({ [e.target.name]: e.target.value });
  }
  handleUpdates = (name, value) => {
    this.setState({
      [name]: value,
      enableUpdate: true
    });
  }

  //RENDER
  render() {
    return (
      <div className='content-view'>
        <div className='centered-container'>
          <div className='content-card singular-card' style={{width: '800px'}}>
            <div>
              <p className='page-header'>Profile</p>
            </div>
            <Row>
              <Col xs='6'>
                <BaseInput name='firstname' startingValue={this.state.user.firstname} changeHandler={this.handleUpdates} label='First Name' type='text' required/>
              </Col>
              <Col xs='6'>
                <BaseInput name='lastname' startingValue={this.state.user.lastname} changeHandler={this.handleUpdates} label='Last Name' type='text' required/>
              </Col>
            </Row>
            <Row>
              <Col xs='12'>
                <BaseInput name='email' startingValue={this.state.user.email} disabled={true} changeHandler={this.handleUpdates} label='Email' type='email' required/>
              </Col>
            </Row>
            <Row>
              <Col xs='12'>
                <BaseInput name='phonenumber' startingValue={this.state.user.phonenumber} changeHandler={this.handleUpdates} label='Phone Number' type='text'/>
              </Col>
            </Row>
            <Row>
              <Col xs='6'>
                <BaseInput name='password' startingValue={'••••••••••'} disabled={true} changeHandler={this.handleUpdates} label='Password' type='text' required/>
              </Col>
              <Col xs='6'>
                <div className='temp-offset-for-input'>
                  <BaseButton content='Change' onClick={this.toggleModal}/>
                </div>
              </Col>
            </Row>
            <div className='centered-row' style={{marginTop: '25px'}}>
              <BaseButton content='Save Changes' classes='reasonably-sized-button' onClick={this.saveChangesClicked} disabled={!this.state.enableUpdate}/>
            </div>
          </div>

          <ConditionalDOM render={this.props.user.desktopaccess}>
            <div className='content-card singular-card' style={{width: '800px', marginBottom: '20px'}}>
              <div>
                <p className='page-header'>Haas Time for Desktop</p>
              </div>
              <div className='centered-row' style={{marginTop: '25px'}}>
                <BaseButton content='Download' classes='reasonably-sized-button' onClick={this.downloadDesktopProgram}/>
              </div>
            </div>
          </ConditionalDOM>
        </div>

        {/* CHANGE PW DIALOG */}
        <Modal isOpen={this.state.isChangePwOpen} backdrop={true} style={{maxWidth: '95vw', width: '450px'}}>
          <ModalBody>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <p className='dialog-header'>Change Password</p>
              <button className='fa fa-window-close' onClick={this.toggleModal} style={{color: 'red', fontSize: '27px', backgroundColor: 'transparent', border: 'none', boxShadow: 'none', outline: 'none'}}/>
            </div>
            <p className='temp-offset-for-input'>Your new password must be 8 characters or more, and contain each of the following: One Number, One Uppercase Letter, One Lowercase Letter. Special characters are recommended.</p>
            <form onSubmit={this.submitNewPassword} autoComplete='off'>
              <BaseInput name='newpw' changeHandler={this.handleUpdates} label='New Password' type='password' required/>
              <BaseInput name='confirmnewpw' changeHandler={this.handleUpdates} label='Confirm New Password' type='password' required/>
              <div style={{marginBottom: '10px'}}></div>
              <p hidden={this.state.errorMessage.length < 1} className='err-text' style={{marginTop: '20px', fontWeight: '400'}}>{this.state.errorMessage}</p>
              <BaseButton content='Update Password' type='submit'/>
            </form>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
    return {
      user: state.user.session,
      cognitoUser: state.user.cognitoUser
    }
  }
const mapDispatchToProps = (dispatch) => {
  return {
    setUserSession: (obj) => dispatch(actions.setUserSession(obj)),
    logout: () => dispatch(actions.logout()),
    showLoading: () => dispatch(actions.showLoading()),
    closeLoading: () => dispatch(actions.closeLoading())
  }
}
  
Profile.propTypes = { 
  user: PropTypes.object.isRequired
}
  
  export default connect(mapStateToProps, mapDispatchToProps)(Profile);