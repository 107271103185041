import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import * as actions from '../actions';
import { urls } from '.';
import ApiHelper from '../utilities/apiHelper';
import { Endpoints } from '../Constants';
import { TimeLogEditor, Timer } from '../components';

class Home extends Component{
  constructor(props) {
    super(props);
    this.state = {
      selectedTimeLog: null
    };
  }
  componentDidMount = async () => {
    if (ApiHelper.isAuthExpired()){
      let refreshed = await ApiHelper.tryRefresh();
      if (!refreshed){
        this.props.history.push(urls.login);
        return;
      }
    }
    this.ensureDataForPage();
  }

  //METHODS
  ensureDataForPage = async () => {
    this.props.showLoading();
    this.props.setTimeLogs([]);

    //get projects
    let response = await ApiHelper.get(Endpoints.Projects);
    if (response.data){
      this.props.setProjectList(response.data);
    }

    //get users
    response = await ApiHelper.get(Endpoints.Users);
    if (response.data){
      this.props.setUserList(response.data);
    }

    //get timers
    response = await ApiHelper.get(Endpoints.TimeLogs + '?current=true');
    if (response.data){
      this.props.setTimeLogs(response.data);
    }

    this.props.closeLoading();
  }
  clockInClicked = async () => {
    let _date = new Date();
    let formattedToday = `${_date.getFullYear()}-${(_date.getMonth() + 1).toString().padStart(2, '0')}-${(_date.getDate()).toString().padStart(2, '0')}`;

    let body = {
      date: formattedToday,
      duration: null,
      idproject: null
    }

    this.props.showLoading();
    let response = await ApiHelper.post(Endpoints.TimeLogs, body);

    if (response.success){
      let timeLogResponse = await ApiHelper.get(Endpoints.TimeLogs + '?current=true');
      if (timeLogResponse.data){
        this.props.setTimeLogs(timeLogResponse.data);
      }
    } else {
      alert('Failed to clock in.');
    }
    this.props.closeLoading();
  }
  recentHistoryClicked = () => {
    this.props.history.push(urls.history);
  }
  projectHistoryClicked = () => {
    this.props.history.push(urls.manageProjects);
  }
  timeLogClicked = (id) => {
    if (!id){
      return;
    }
    let selectedTimeLog = this.props.timeLogs.filter(el => el.idtimelog === id)[0];
    this.setState({ selectedTimeLog });
  }
  unselectTimeLog = () => {
    this.setState({selectedTimeLog: null});
  }
  reloadList = () => {
    this.setState({selectedTimeLog: null});
    this.ensureDataForPage();
  }
  timerStatusChanged = () => {
    this.props.setTimeLogs([]);
    this.ensureDataForPage();
  }

  //EVENT HANDLERS
  onchange = (e) => {
    if (!e) { return; }
    this.setState({ [e.target.name]: e.target.value });
  }

  //RENDER
  render() {
    return (
      <div className='content-view'>
        <div className='centered-container'>
          <div className='content-card singular-card' style={{width: '1000px'}}>

          <Row>
            <Col xs='12'>
              <p className='page-header'>Home</p>
            </Col>
          </Row>

          <div style={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-evenly', maxWidth: '100vw'}}>
            <div onClick={this.clockInClicked} className='tile-button'>
              <p className='tile-button-content'>Clock In</p>
            </div>
            <div onClick={this.recentHistoryClicked} className='tile-button'>
              <p className='tile-button-content'>My Time Logs</p>
            </div>
            <div onClick={this.projectHistoryClicked} className='tile-button'>
              <p className='tile-button-content'>Manage Projects</p>
            </div>
          </div>

          <Row>
            <Col xs='12'>
              <p className='page-header'>Current Timers</p>
            </Col>
          </Row>
          {
            this.props.timeLogs && this.props.timeLogs.length > 0 ?
            this.props.timeLogs.map((t, i) => (
              <Timer key={'timer'+i} timeLog={t} timerStatusChanged={this.timerStatusChanged} onClick={this.timeLogClicked}/>
            ))
            : 
            <p>There are no currently active timers.</p>
          }
          </div>
        </div>

        {/* EDIT TIME LOG */}
        <TimeLogEditor timeLog={this.state.selectedTimeLog} showHours={true} unselect={this.unselectTimeLog} reloadCb={this.reloadList}/>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
    return {
      user: state.user.session,
      projects: state.projects.list,
      timeLogs: state.timeLogs.list
    }
  }
const mapDispatchToProps = (dispatch) => {
  return {
    setUserSession: (obj) => dispatch(actions.setUserSession(obj)),
    logout: () => dispatch(actions.logout()),
    showLoading: () => dispatch(actions.showLoading()),
    closeLoading: () => dispatch(actions.closeLoading()),
    setProjectList: (arr) => dispatch(actions.setProjectList(arr)),
    setUserList: (arr) => dispatch(actions.setUserList(arr)),
    setTimeLogs: (arr) => dispatch(actions.setTimeLogList(arr))
  }
}
  
Home.propTypes = { 
  user: PropTypes.object.isRequired
}
  
  export default connect(mapStateToProps, mapDispatchToProps)(Home);