import { Component } from 'react';

class ConditionalDOM extends Component {
  constructor(props) {
    super(props);
    this.state = {render: props.render || false}
  }
  componentDidUpdate = () => {
    if (this.state.render !== this.props.render){
      this.setState({render: this.props.render});
    }
  }
  render() {
    return (
      this.state.render && this.props.children ?
        this.props.children
        : null
    )
  }
}

export default ConditionalDOM;