import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import { BaseButton, BaseDropDown, BaseInput, ConditionalDOM } from './';
import ApiHelper from '../utilities/apiHelper';
import Helpers from '../utilities/helpers';
import { Endpoints } from '../Constants';
import Logger from '../utilities/loggingUtility';

class TimeLogEditor extends Component {
  constructor(props){
    super(props);
    this.state = {
      isOpen: false,
      timeLog: null,
      updateHrs: false
    };
  }
  componentDidUpdate = (prevProps) => {
    if (this.state.isOpen || !this.props.timeLog){
      return;
    }
    this.setState({ timeLog: this.props.timeLog, isOpen: true, updateHrs: false });
  }

  //METHODS
  selectedProjectChanged = (selection) => {
    let timeLog = {...this.state.timeLog, project: selection};
    this.setState({timeLog});
  }
  editClicked = async () => {
    if (!window.confirm('Are you sure that you want to modify this time log?')){
      return;
    }
    let timeLog = this.state.timeLog;
    if (timeLog.project){
      timeLog.idproject = timeLog.project.idproject;
    } else {
      alert('Error: Invalid project for time log.');
      Logger.LogError(this.state, true);
      return;
    }
    if (this.props.showHours && this.state.updateHrs){
      timeLog.duration = Math.round(timeLog.duration * 3600);
      timeLog.state = 'paused';
    }
    if (!Helpers.IsToday(timeLog.date)){
      timeLog.state = 'stopped';
    }

    let response = await ApiHelper.put(Endpoints.TimeLogs, timeLog);

    if (!response.success){
      alert('Something went wrong while trying to edit this time log.');
      return;
    }
    if (this.props.reloadCb){
      this.props.reloadCb();
      this.close();
    }
  }
  deleteClicked = async () => {
    if (!window.confirm('Are you sure that you want to delete this time log?')){
      return;
    }
    let response = await ApiHelper.delete(Endpoints.TimeLogs + '?id=' + this.state.timeLog.idtimelog);
    
    if (!response.success){
      alert('Something went wrong while trying to delete this time log.');
      return;
    }
    if (this.props.reloadCb){
      this.props.reloadCb();
      this.close();
    }
  }
  close = () => {
    this.setState({ isOpen: false, timeLog: null });
    this.props.unselect();
  }
  getHours = (val) => {
    if (!val){ return ''; }

    let idx = val.indexOf(' ');
    if (idx < 0){ return val; }

    return val.substring(0, idx);
  }
  incrementDuration = (sec) => {
    let newDuration = this.state.timeLog.duration + sec;
    if (newDuration < 0){
      alert('Hours can\'t be negative.');
      return;
    }
    this.setState((prevState) => {
      let timeLog = prevState.timeLog;
      timeLog.duration = newDuration;
      timeLog.durationhrs = Helpers.ConvertSecToHrs(newDuration) + ' hrs';
      return { timeLog }
    });
  }

  //EVENTS
  handleUpdates = (name, value) => {
    this.setState((prevState) => {
      let timeLog = prevState.timeLog;
      timeLog[name] = value;

      if (name === 'duration' && !prevState.updateHrs){
        return { timeLog, updateHrs: true }
      } else {
        return { timeLog }
      }
    });
  }

  //THE RENDER FUNCTION
  render() {
    if (!this.props.timeLog || !this.state.isOpen){
      return null;
    }
    return (
      <Modal isOpen={this.state.isOpen} backdrop={true} style={{maxWidth: '95vw', width: '550px'}}>
        <ModalBody>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <p className='dialog-header'>Edit Time Log</p>
            <button className='fa fa-window-close' onClick={this.close} style={{color: 'red', fontSize: '27px', backgroundColor: 'transparent', border: 'none', boxShadow: 'none', outline: 'none'}}/>
          </div>
          {/* <p>Subtext, instructions..</p> */}

          {/* drop down */}
          <div style={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-between'}}>
            <div style={{margin: '0px'}}>
              <BaseDropDown items={this.props.projects} 
                emptyText='No Projects to Show' 
                placeholder={this.state.timeLog.project && this.state.timeLog.project.title ? this.state.timeLog.project.title : 'Select a project'} 
                titleProp='title' 
                selectionChanged={this.selectedProjectChanged}/>
            </div>
          </div>

          <Row>
            <Col xs='12' md='6'>
              <BaseInput name='date' startingValue={this.state.timeLog.date} changeHandler={this.handleUpdates} label='Date' type='date' required/>
            </Col>
            <ConditionalDOM render={this.props.showHours}>
              <Col xs='12' md='6'>
                <BaseInput name='duration' 
                  startingValue={this.props.showHours ? this.getHours(this.state.timeLog.durationhrs) : null} 
                  tooltip={this.state.timeLog.duration > 24 ? Helpers.FormatTimeText(this.state.timeLog.duration) : ''} 
                  changeHandler={this.handleUpdates} 
                  label='Hours' 
                  type='text'/>
              </Col>
            </ConditionalDOM>
            <ConditionalDOM render={this.props.showBillableAmount}>
              <Col xs='12' md='6'>
                <BaseInput name='billableamount' startingValue={this.state.timeLog.billableamount} changeHandler={this.handleUpdates} label='Billable Amount' type='text'/>
              </Col>
            </ConditionalDOM>
          </Row>
          <ConditionalDOM render={this.props.showHours && Helpers.IsToday(this.state.timeLog.date)}>
            <div style={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-between', paddingTop: '30px'}}>
              <button onClick={() => this.incrementDuration(60)} className='incrementor-btn'>+ 1 min</button>
              <button onClick={() => this.incrementDuration(-60)} className='incrementor-btn'>- 1 min</button>
              <button onClick={() => this.incrementDuration(600)} className='incrementor-btn'>+ 10 min</button>
              <button onClick={() => this.incrementDuration(-600)} className='incrementor-btn'>- 10 min</button>
              <button onClick={() => this.incrementDuration(3600)} className='incrementor-btn'>+ 1 hr</button>
              <button onClick={() => this.incrementDuration(-3600)} className='incrementor-btn'>- 1 hr</button>
            </div>
          </ConditionalDOM>
          <Row>
            <Col xs='12'>
              <BaseInput name='notes' classes='timelog-notes-input' startingValue={this.state.timeLog.notes} changeHandler={this.handleUpdates} label='Notes' type='textarea'/>
            </Col>
          </Row>
          
          <div style={{marginBottom: '1em'}}></div>
          <Row>
            <Col xs='12' lg='6'>
              <BaseButton content='Save Edits' onClick={this.editClicked}/>
            </Col>
            <Col xs='12' lg='6'>
              <BaseButton content='Delete Time Log' classes='delete-button' onClick={this.deleteClicked}/>
            </Col>
          </Row>
        </ModalBody>
      </Modal>    
    )
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.projects.list
  }
}
const mapDispatchToProps = (dispatch) => {
  return { }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeLogEditor);
