import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Input, Label, Row } from 'reactstrap';
import * as actions from '../actions';
import { urls } from '.';
import ApiHelper from '../utilities/apiHelper';
import { BaseButton, BaseDropDown, BaseInput, ConditionalDOM } from '../components';
import { Endpoints } from '../Constants';
import Logger from '../utilities/loggingUtility';
import Helpers from '../utilities/helpers';

class TimeLogs extends Component{
  constructor(props){
    super(props);
    try {
      this.state = {
        clockInOnly: false,
        date: Helpers.GetTodaysDate(),
        selectedProject: {},
        timeLog: {}
      };

      let timeLog = { date: Helpers.GetTodaysDate() };

      if (!this.props.history.location.search){
        this.state = {...this.state, timeLog };
        return;
      }
      var str = this.props.history.location.search.substring(1);
      var params = str.split('&');
  
      let clockInOnly = false;
      let editMode = false;
      timeLog = this.state.timeLog;
  
      //get query params
      for(let p of params){
        let parts = p.toLowerCase().split('=');
        if (!parts || parts.length !== 2){
          continue;
        }

        let key = parts[0];
        let val = parts[1];
        if (key === 'mode' && val === 'clockin'){
          clockInOnly = true;
        }
        if (key === 'mode' && val === 'edit'){
          editMode = true;
        }
        if (editMode && key === 'id'){
          timeLog = this.props.timeLogs.filter(el => el.idtimelog.toString() === val)[0];
        }
      }

      if (editMode){
        if (!timeLog){
          alert('The chosen time log was not loaded correctly. This is a temporary bug. You are now in "add mode" for this page.');
        }
        this.state = {
          ...this.state, clockInOnly, editMode, timeLog, selectedProject: timeLog.project, date: timeLog.date
        };
      } else {
        this.state = {
          ...this.state, clockInOnly, timeLog
        }
      }
    }
    catch (err){
      Logger.LogError(err);
    }
  }
  componentDidMount = async () => {
    this.props.closeLoading();
    if (ApiHelper.isAuthExpired()){
      let refreshed = await ApiHelper.tryRefresh();
      if (!refreshed){
        this.props.history.push(urls.login);
        return;
      }
    }
    this.ensureDataForPage();
  }

  //METHODS
  ensureDataForPage = async () => {
    if (this.props.projects && this.props.projects.length > 0){
      return;
    }
    this.props.showLoading();
    let response = await ApiHelper.get(Endpoints.Projects);
    if (response.data){
      this.props.setProjectList(response.data);
    }
    this.props.closeLoading();
  }
  saveTimeLog = async (e) => {
    e.preventDefault();

    //validate
    if (!this.state.selectedProject.idproject || this.state.selectedProject.idproject < 1){
      alert('Please select a project for this time log.');
      return;
    }
    if (!this.state.timeLog.duration){
      alert('Please enter a duration for this time log, or use the clock in button.');
      return;
    }
    if (this.state.timeLog.toString().indexOf('-') >= 0){
      alert('Invalid Hours.');
      return;
    }

    //save data; redirect to history if success
    this.props.showLoading();

    let timeLog = this.state.timeLog;
    timeLog.duration = Math.round(this.state.timeLog.duration * 3600);
    timeLog.idproject = this.state.selectedProject.idproject;
    timeLog.date = this.state.date;

    let response = await ApiHelper.post(Endpoints.TimeLogs, timeLog);

    if (response.success){
      this.props.setTimeLogs();
      alert('Saved the time log.');
      this.props.history.push(urls.history);
    } else {
      alert('Failed to create the time log.');
      this.props.closeLoading();
    }
  }
  clockIn = async (e) => {
    e.preventDefault();

    if (this.state.timeLog.duration && this.state.timeLog.duration.length > 0){
      if (!window.confirm('The number of hours you entered will be ignored when clocking in for a project.')){
        return;
      }
    }

    this.props.showLoading();
    let response = await ApiHelper.post(Endpoints.TimeLogs, {...this.state.timeLog, idproject: this.state.selectedProject.idproject, date: this.state.date, duration: null});

    if (response.success){
      this.props.setTimeLogs();
      this.setState({timeLog: {...this.state.timeLog, idtimelog: response.data.idtimelog}});
      alert('Clocked in!');
      this.props.history.push(urls.history);
    } else {
      alert('Failed to clock in.');
    }
    this.props.closeLoading();
  }
  editTimeLog = async (e) => {
    e.preventDefault();

    if (!this.state.selectedProject.idproject || this.state.selectedProject.idproject < 1){
      alert('Please select a project for this time log.');
      return;
    }
    if (this.state.timeLog.duration < 0){
      alert('Number of hours can\'t be negative.');
      return;
    }

    this.props.showLoading();

    let timeLog = this.state.timeLog;
    timeLog.date = this.state.date;
    timeLog.duration = this.state.timeLog.duration * 3600;
    timeLog.idproject = this.state.selectedProject.idproject;
    timeLog.state = 'stopped';
    
    let response = await ApiHelper.put(Endpoints.TimeLogs, timeLog);

    if (response.success){
      this.props.setTimeLogs([]);
      this.props.history.push(urls.history);
      return;
    }
    
    alert('Failed to edit the time log.');
    this.props.closeLoading();
  }
  deleteTimeLog = async () => {
    if (!window.confirm('Are you sure you want to delete this time log?')){
      return;
    }

    this.props.showLoading();
    let response = await ApiHelper.delete(Endpoints.TimeLogs + '?id=' + this.state.timeLog.idtimelog);
    
    if (response.success){
      this.props.setTimeLogs();
      alert('Deleted!');
      this.props.history.push(urls.history);
    } else {
      alert('Something went wrong while trying to delete this time log.')
    }
    this.props.closeLoading();
  }
  getHours = (val) => {
    if (!val){ return ''; }

    let idx = val.indexOf(' ');
    if (idx < 0){ return val; }

    return val.substring(0, idx);
  }

  //EVENT HANDLERS
  onchange = (e) => {
    if (!e) { return; }
    this.setState({ [e.target.name]: e.target.value });
  }
  handleUpdates = (name, value) => {
    this.setState((prevState) => {
      let timeLog = {...prevState.timeLog};
      timeLog[name] = value;

      return { timeLog }
    });
  }
  selectedProjectChanged = (selectedProject) => {
    this.setState({selectedProject});
  }

  //RENDER
  render() {
    return (
      <div className='content-view'>
        <div className='centered-container'>
          <div className='content-card singular-card' style={{width: '800px'}}>
            <Row>
              <Col xs='12'>
                <p className='page-header temp-offset-for-input'>Add Time Log</p>
              </Col>
            </Row>
            <div style={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-between'}}>
              <div style={{margin: '30px 0px 0px 0px'}}>
                <BaseDropDown items={this.props.projects} 
                  emptyText='No Projects to Show' 
                  placeholder={this.state.selectedProject.title ? this.state.selectedProject.title : 'Select a project'} 
                  titleProp='title' 
                  selectionChanged={this.selectedProjectChanged}/>
              </div>
            </div>
            <Row hidden={this.state.clockInOnly}>
              <Col xs='12' md='6'>
                <Label for='date' className='field-active'>{'Date'}</Label>
                <Input type='date' name='date' value={this.state.date} className='datepicker' onChange={this.onchange}/>
              </Col>
              <Col xs='12' md='6'>
                <BaseInput name='duration' startingValue={this.state.editMode ? this.getHours(this.state.timeLog.durationhrs) : null} changeHandler={this.handleUpdates} label='Hours' type='text'/>
              </Col>
            </Row>
            <Row>
              <Col xs='12'>
                <BaseInput name='notes' startingValue={this.state.timeLog.notes} changeHandler={this.handleUpdates} label='Notes' type='textarea'/>
              </Col>
            </Row>
            <ConditionalDOM render={this.state.editMode}>
              <Row>
                <Col xs='12' md='6'>
                  <BaseButton content='Save Edits' classes='reasonably-sized-button' onClick={this.editTimeLog}/>
                </Col>
                <Col xs='12' md='6'>
                  <BaseButton content='Delete' classes='reasonably-sized-button delete-button' onClick={this.deleteTimeLog}/>
                </Col>
              </Row>
            </ConditionalDOM>
            <ConditionalDOM render={!this.state.editMode}>
              <Row>
                <Col xs='12' md='6'>
                  <BaseButton content='Clock In' classes='reasonably-sized-button' onClick={this.clockIn}/>
                </Col>
                <Col xs='12' md='6'>
                  <BaseButton content='Save Time Log' classes='reasonably-sized-button' onClick={this.saveTimeLog}/>
                </Col>
              </Row>
            </ConditionalDOM>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
    return {
      user: state.user.session,
      projects: state.projects.list,
      timeLogs: state.timeLogs.list
    }
  }
const mapDispatchToProps = (dispatch) => {
  return {
    setUserSession: (obj) => dispatch(actions.setUserSession(obj)),
    logout: () => dispatch(actions.logout()),
    showLoading: () => dispatch(actions.showLoading()),
    closeLoading: () => dispatch(actions.closeLoading()),
    setTimeLogs: (arr) => dispatch(actions.setTimeLogList(arr)),
    setProjectList: (arr) => dispatch(actions.setProjectList(arr))
  }
}
  
TimeLogs.propTypes = { 
  user: PropTypes.object.isRequired
}
  
  export default connect(mapStateToProps, mapDispatchToProps)(TimeLogs);