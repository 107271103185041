import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import * as actions from '../actions';

class Error extends Component{
  constructor(props){
    super(props);
    this.state = {};
  }

  //EVENT HANDLERS
  onchange = (e) => {
    if (!e) { return; }
    this.setState({ [e.target.name]: e.target.value });
  }

  //RENDER
  render() {
    return (
      <Row className='content-view'>
        <Col xs='12' style={{textAlign: 'center'}}>
          <h1>Error</h1>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = (state) => {
    return {
      user: state.user.session,
    }
  }
const mapDispatchToProps = (dispatch) => {
  return {
    setUserSession: (obj) => dispatch(actions.setUserSession(obj)),
    logout: () => dispatch(actions.logout()),
    showLoading: () => dispatch(actions.showLoading()),
    closeLoading: () => dispatch(actions.closeLoading())
  }
}
  
Error.propTypes = { 
  user: PropTypes.object.isRequired
}
  
  export default connect(mapStateToProps, mapDispatchToProps)(Error);