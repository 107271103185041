import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../actions';
import { urls } from '.';
import ApiHelper from '../utilities/apiHelper';
import { Endpoints } from '../Constants';
import { BaseButton, BaseDropDown, ConditionalDOM, TimeLog, TimeLogEditor } from '../components';
import Helpers from '../utilities/helpers';

class History extends Component{
  constructor(props){
    super(props);
    this.state = {
      dateSortOptions: ['15 days', '30 days', '60 days'],
      filteredList: props.timelogs || [],
      monthHours: 0,
      selectedProject: {},
      selectedTimeLog: {},
      todaysHours: 0,
      weekHours: 0,
    };
  }
  componentDidMount = async () => {
    if (ApiHelper.isAuthExpired()){
      let refreshed = await ApiHelper.tryRefresh();
      if (!refreshed){
        this.props.history.push(urls.login);
        return;
      }
    }
    this.ensureDataForPage();
  }

  //METHODS
  ensureDataForPage = async () => {
    this.props.showLoading();
    this.props.setTimeLogs([]);

    let response = await ApiHelper.get(Endpoints.TimeLogs + '?recent=true');
    if (response.success){
      this.formatList(response.data);
    }
    this.props.closeLoading();
  }
  formatList = async (list) => {
    let todayTotal = 0;
    let weekTotal = 0;
    let monthTotal = 0;

    let today = Helpers.GetTodaysDate();
    let weekStart = Helpers.GetWeekStart();
    let monthStart = Helpers.GetMonthStart();
    let daySec = 0;
    
    for(let i in list){
      let log = list[i];
      let sec = JSON.parse(log.duration);

      //set previous item's day total
      if (i != 0 && log.date !== list[i-1].date){
        list[i-1].dayTotal = Helpers.ConvertSecToHrs(daySec);
        daySec = 0;
      }

      //add to day total
      daySec += log.duration;
      log.dayTotal = '';

      //add to today's hours
      if (log.date === today){
        todayTotal += sec;
      }
      //add to this week's hours
      if (log.date >= weekStart){
        weekTotal += sec;
      }
      //add to this month's hours
      if (log.date >= monthStart){
        monthTotal += sec;
      }
    }

    //set last item's day total
    list[list.length - 1].dayTotal = Helpers.ConvertSecToHrs(daySec);

    this.props.setTimeLogs(list);
    this.setState({
      todaysHours: Helpers.ConvertSecToHrs(todayTotal), 
      weekHours: Helpers.ConvertSecToHrs(weekTotal), 
      monthHours: Helpers.ConvertSecToHrs(monthTotal), 
      filteredList: list});
    this.props.closeLoading();
  }
  addTimeLogClicked = () => {
    this.props.history.push(urls.timeLogs);
  }
  sortOptionChanged = async (selectedProject) => {
    this.setState({ selectedProject, showClearSort: true });

    //retrieve project id
    let id = this.props.projects.filter(el => el.title === selectedProject.title)[0].idproject;
    if (!id){
      alert('Something went wrong with sorting by project.');
      return;
    }
    let params = '?project='+id;

    //handle start date of logs
    if (this.state.selectedDateSortOption){
      let option = this.state.selectedDateSortOption;
      let start = new Date();
      let numDays = JSON.parse(option.substring(0, option.indexOf(' ')));
      start.setDate(start.getDate() - numDays);
      params += `&start=${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`
    }

    this.props.showLoading();
    let response = await ApiHelper.get(Endpoints.TimeLogs + params);
    let filteredList = [];
    if (response.data){
      filteredList = response.data;
    }
    this.setState({filteredList});
    this.props.closeLoading();
  }
  clearSortOption = () => {
    this.setState({showClearSort: false, selectedProject: {}, filteredList: this.props.timelogs});
  }
  viewHistoryClicked = async () => {
    this.props.showLoading();
    this.props.setTimeLogs([]);
    let response = await ApiHelper.get(Endpoints.TimeLogs);
    if (response.data){
      this.setState({viewHistory: true, showClearSort: false, filteredList: []});
      this.formatList(response.data);
    } else {
      alert('No additional Time Logs to show.');
    }
    this.props.closeLoading();
  }
  timeLogClicked = (id) => {
    if (!id){
      return;
    }
    let selectedTimeLog = this.state.filteredList.filter(el => el.idtimelog === id)[0];
    this.setState({ selectedTimeLog });
  }
  unselectTimeLog = () => {
    this.setState({selectedTimeLog: null});
  }
  reloadList = () => {
    if (this.state.viewHistory){
      this.viewHistoryClicked();
    } else {
      this.setState({selectedTimeLog: null, filteredList: []});
      this.ensureDataForPage();
    }
  }

  //EVENT HANDLERS
  onchange = (e) => {
    if (!e) { return; }
    this.setState({ [e.target.name]: e.target.value });
  }

  //RENDER
  render() {
    return (
      <div className='content-view'>
        <div className='centered-container'>
          <div className='content-card singular-card' style={{width: '900px'}}>
            <div>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <div>
                  <p className='page-header'>Recent Time Logs</p>
                  <p className='italic-text'>Hours worked today: {this.state.todaysHours}</p>
                  <p className='italic-text'>This Week: {this.state.weekHours}</p>
                  <p className='italic-text'>This Month: {this.state.monthHours}</p>
                </div>
                <div>
                  <BaseButton content='Add Time Log' classes='reasonably-sized-button' onClick={this.addTimeLogClicked}/>
                </div>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'left', margin: '1em 0px'}}>
                <BaseDropDown items={this.props.projects} 
                  emptyText='No Projects to Show' 
                  placeholder={this.state.selectedProject.title ? this.state.selectedProject.title : 'Sort by project'} 
                  titleProp='title' 
                  selectionChanged={this.sortOptionChanged}/>
                <ConditionalDOM render={this.state.showClearSort}>
                  <button onClick={this.clearSortOption} className='close-detail-button no-offset'>Clear</button>
                </ConditionalDOM>
              </div>
            </div>
            <ConditionalDOM render={this.state.filteredList && this.state.filteredList.length > 0}>
              <div style={{overflow: 'auto', minHeight: '300px', height: '50vh'}}>
                <div className='grid-row'>
                  <div className='grid-cell' style={{width: '100px'}}><p className='grid-header'>Date</p></div>
                  <div className='grid-cell' style={{width: '160px'}}><p className='grid-header'>Project</p></div>
                  <div className='grid-cell' style={{width: '80px'}}><p className='grid-header'>Hours</p></div>
                  <div className='grid-cell' style={{width: '450px'}}><p className='grid-header'>Description</p></div>
                  <div className='grid-cell ghost-cell' style={{width: '60px'}}><p className='grid-header'>{' '}</p></div>
                </div>
                {
                  this.state.filteredList.map((log, i) => (
                    <TimeLog key={'l' + log.idtimelog} timeLog={log} showDayTotal={true} clickHandler={this.timeLogClicked}/>
                  ))
                }
              </div>
            </ConditionalDOM>
            <ConditionalDOM render={!this.state.filteredList || this.state.filteredList.length < 1}>
              {/* <p style={{marginTop: '1em'}}>{this.state.selectedSortOption ?  'No time logs for the selected project.' : 'You don\'t have any time logs for this month yet.'}</p> */}
              <p className='italic-text' style={{marginTop: '1em'}}>{'No time logs in the current month.'}</p>
            </ConditionalDOM>
            <ConditionalDOM render={!this.state.viewHistory}>
              <div style={{borderTop: '1px solid grey', marginTop: '20px', paddingTop: '5px', textAlign: 'center'}}>
                <BaseButton content='View Full History' classes='reasonably-sized-button' onClick={this.viewHistoryClicked}/>
              </div>
            </ConditionalDOM>
          </div>
        </div>

        {/* EDIT TIME LOG */}
        <TimeLogEditor timeLog={this.state.selectedTimeLog} showHours={true} unselect={this.unselectTimeLog} reloadCb={this.reloadList}/>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
    return {
      user: state.user.session,
      timelogs: state.timeLogs.list,
      projects: state.projects.list
    }
  }
const mapDispatchToProps = (dispatch) => {
  return {
    setUserSession: (obj) => dispatch(actions.setUserSession(obj)),
    logout: () => dispatch(actions.logout()),
    showLoading: () => dispatch(actions.showLoading()),
    closeLoading: () => dispatch(actions.closeLoading()),
    setTimeLogs: (arr) => dispatch(actions.setTimeLogList(arr))
  }
}
  
History.propTypes = { 
  user: PropTypes.object.isRequired
}
  
export default connect(mapStateToProps, mapDispatchToProps)(History);