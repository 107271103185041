import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { BaseInput, ConditionalDOM } from '../components';

class Upload extends Component {
  constructor(props){
    super(props);
    this.state = {
      progress: ''
    };
  }

  //RENDER
  render() {
    return (
      <div className='upload-item'>
        <p style={{padding: '0px', margin: '0px'}}>{this.props.label}</p>
        <ConditionalDOM render={!this.props.uploadState || this.props.uploadState === 'notstarted'}>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <ConditionalDOM render={this.props.changeHandler}>
              <div style={{padding: '0px 20px'}}>
                <BaseInput name={'amnt-'+this.props.item.file.name} classes='upload-input' changeHandler={this.props.changeHandler} type='text'/>
              </div>
              {/* <div>
                <BaseInput name={'desc-'+this.props.item.file.name} classes='upload-input' changeHandler={this.props.changeHandler} type='text'/>
              </div> */}
            </ConditionalDOM>
            <Button className='temp-clock-out-button rm-f-button' onClick={() => this.props.removeUpload(this.props.item.file.lastModified)}>X</Button>
          </div>
        </ConditionalDOM>
        <ConditionalDOM render={this.props.uploadState === 'started'}>
          <div className='small-loader'>
            <svg className='circular' viewBox='25 25 50 50'>
              <circle className='path' cx='50' cy='50' r='20' fill='none' strokeWidth='2' strokeMiterlimit='10'/>
            </svg>
          </div> 
        </ConditionalDOM>
        <ConditionalDOM render={this.props.uploadState === 'done' || this.props.uploadState === 'saving'}>
          <p style={{padding: '0px', margin: '0px', color: 'green'}}>Done!</p>
        </ConditionalDOM>
      </div>
    )
  }
}

export default Upload;