import React, { Component } from 'react';
import { Button } from 'reactstrap';
class BaseButton extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Button style={baseStyle} className={this.props.classes} onClick={this.props.onClick} disabled={this.props.disabled || false}>{this.props.content}</Button>
    )
  }
}

const baseStyle = {
  backgroundColor: '#668cff',
  color: '#ffffff',
  borderRadius: '0px',
  outline: 'none',
  border: 'none',
  width: '100%',
  height: '50px',
  marginTop: '20px'
}

export default BaseButton;
