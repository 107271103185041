const initialState = {
    loading: false
}

const modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'modal/SHOW_LOADING':
            return {
                ...state,
                loading: action.payload
            }
        case 'modal/CLOSE_LOADING':
            return {
                ...state,
                loading: action.payload
            }
        case '*/CLEAR_STORE':
            return initialState;
        default:
            return state;
    }
}

export default modalReducer;