import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import * as actions from '../actions';
import { Endpoints } from '../Constants';
import { urls } from '.';
import ApiHelper from '../utilities/apiHelper';
import Helpers from '../utilities/helpers.js';
import { BaseButton, BaseInput, ConditionalDOM } from '../components';

class ManageUsers extends Component{
  constructor(props){
    super(props);
    this.state = {
      accountingemail: '',
      isAddFormVisible: false,
      enableUpdate: false,
      selectedUser: {}
    };
  }
  componentDidMount = async () => {
    if (ApiHelper.isAuthExpired()){
      let refreshed = await ApiHelper.tryRefresh();
      if (!refreshed){
        this.props.history.push(urls.login);
        return;
      }
    }
    this.ensureDataForPage();
  }

  //METHODS
  ensureDataForPage = async () => {
    if (this.props.users && this.props.users.length > 0){
      return;
    }
    this.props.showLoading();
    let response = await ApiHelper.get(Endpoints.Users);
    if (response.data){
      this.props.setUserList(response.data);
    }
    this.props.closeLoading();
  }
  showUserDetail = (selectedUser) => {
    this.setState({ selectedUser, enableUpdate: false, isAddFormVisible: false });
  }
  closeDetail = () => {
    this.setState({ selectedUser: {}, enableUpdate: false, isAddFormVisible: false });
  }
  updateUser = async () => {
    if (!this.state.selectedUser.firstname || this.state.selectedUser.firstname.length < 1 || !this.state.selectedUser.lastname || this.state.selectedUser.lastname.length < 1){
      alert('Give the user a first name and a last name.');
      return;
    }
    this.props.showLoading();
    let response = await ApiHelper.put(Endpoints.Users, this.state.selectedUser);

    if (response.success){
      alert('Updated this user\'s information.');
      this.props.setUserList([]);
      this.ensureDataForPage();
    } else {
      alert('Failed to update the user.');
    }
    this.props.closeLoading();
  }
  deleteUser = async () => {
    let confirmed = window.confirm('Are you sure that you want to delete this user\'s account permanently? Effective immediately, they will be unable to access the website.');
    if (!confirmed){
      return;
    }
    this.props.showLoading();
    let response = await ApiHelper.delete(Endpoints.Users + '?id=' + this.state.selectedUser.iduser);
    
    if (response.success){
      let list = this.props.users.filter(u => u.iduser !== this.state.selectedUser.iduser);
      this.props.setUserList(list);
      this.closeDetail();
    } else {
      alert('Something went wrong while trying to delete this user.')
    }
    this.props.closeLoading();
  }
  resetUserPw = async () => {
    let derpFlag = this.state.selectedUser.iduser === this.props.user.iduser;
    let message = derpFlag ? 'Are you sure that you want to reset your own password? You will be signed out immediately, then sent an email with a new password and instructions on how to log in again.'
                            : 'Are you sure that you want to reset this user\'s password? This will send them an email with instructions on how to log in again.';

    let confirmed = window.confirm(message);
    if (!confirmed){
      return;
    }
    
    this.props.showLoading();
    let response = await ApiHelper.put(Endpoints.ResetUserPw + '?id=' + this.state.selectedUser.iduser);

    if (response.success){
      alert(derpFlag ? 'Your password was reset. You will now be signed out.' : 'The user\'s password was reset successfully, and they have been emailed a temporary password.');
      if (derpFlag){
        this.props.closeLoading();
        this.props.history.push(urls.login);
        return;
      }
      this.closeDetail();
    } else if (response.status === 404) {
      alert('The user was not found.');
    } else {
      alert('Something went wrong while trying to reset this user\'s password.');
    }
    this.props.closeLoading();
  }
  showAddForm = () => {
    let user = {
      iduser: 1,
      firstname: '',
      lastname: '',
      email: '',
      phonenumber: '',
      manageinvoices: false,
      manageprojects: false,
      manageusers: false
    };
    this.setState({ isAddFormVisible: true, selectedUser: user });
  }
  createUser = async () => {
    if (!this.state.selectedUser.firstname || this.state.selectedUser.firstname.length < 1 || !this.state.selectedUser.lastname || this.state.selectedUser.lastname.length < 1){
      alert('Give the user a first name and a last name.');
      return;
    }
    this.props.showLoading();
    let response = await ApiHelper.post(Endpoints.Users, this.state.selectedUser);

    if (response.success){
      this.props.setUserList([]);
      this.ensureDataForPage();
      let user = {...this.state.selectedUser, iduser: response.data.iduser};
      this.setState({
        selectedUser: user,
        isAddFormVisible: false
      });
      alert(`Created an account for ${user.firstname}, and emailed them their new password.`);
    } else if (response && response.status === 409) {
      alert('A user with that email already exists.');
    } else {
      alert('Something went wrong while trying to create the user.');
    }
    this.props.closeLoading();
  }
  updateAccountantEmail = async () => {
    console.log(this.state.accountingemail);
    if (!Helpers.IsValidEmail(this.state.accountingemail)){
      alert('Please enter a valid email and try again.')
      return;
    }

    this.props.showLoading();
    let response = await ApiHelper.put(Endpoints.SystemSettings, {accountantemail: this.state.accountingemail});
    this.props.closeLoading();
    if (!response.success){
      console.log(response);
      return;
    }
    alert('Successfully updated the email address for HCG\'s Accountant.');
  }


  //EVENT HANDLERS
  onchange = (e) => {
    if (!e) { return; }
    this.setState({ [e.target.name]: e.target.value });
  }
  permissionChanged = (e) => {
    let name = e.target.name;
    this.setState((prevState) => {
      let selectedUser = prevState.selectedUser;
      selectedUser[name] = !selectedUser[name];
      return { selectedUser }
    });
  } 
  handleUpdates = (name, value) => {
    this.setState((prevState) => {
      let user = {...prevState.selectedUser};
      user[name] = value;

      return { selectedUser: user }
    });
  }
  customChangeHandler = (prop, value) => {
    this.setState((prevState) => {
      prevState[prop] = value;

      return prevState;
    });
  }

  //RENDER
  render() {
    return (
      <div className='content-view'>
        <div className='centered-container'>
          <div className='content-card singular-card' style={{width: '800px'}}>
            {/* MAIN VIEW */}
            <div hidden={this.state.selectedUser.iduser >= 0}>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <p className='page-header'>Manage Users</p>
                <button onClick={this.showAddForm} className='add-button'>Add</button>
              </div>
              <Row>
                <Col xs='12'>
                  {/* <p className='italic-text'>Click on a user below to view details</p> */}
                  <p className='italic-text'>{this.props.users.length > 0 ? 'Select a user below to view details' : 'There are currently no active users.. this is a bug, so please report it to topher@simplethesis.com'}</p>
                </Col>
              </Row>
              {
                this.props.users.map((user, i) => (
                  <div id={'u'+i} key={'u'+i} className='item-container' onClick={() => this.showUserDetail(user)}>
                    <p className='temp-data-label'>{user.fullname}</p>
                  </div>
                ))
              }
            </div>
            {/* DETAIL VIEW */}
            {
              this.state.selectedUser && this.state.selectedUser.iduser ?
                <div>
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <p className='page-header temp-offset-for-input'>{this.state.isAddFormVisible ? 'Add User' : 'User Details'}</p>
                    <button onClick={this.closeDetail} className='close-detail-button'>{this.state.isAddFormVisible ? 'Cancel' : 'Close'}</button>
                  </div>
                  <Row>
                    <Col xs='6'>
                      <BaseInput name='firstname' startingValue={this.state.selectedUser.firstname} changeHandler={this.handleUpdates} label='First Name' type='text' required/>
                    </Col>
                    <Col xs='6'>
                      <BaseInput name='lastname' startingValue={this.state.selectedUser.lastname} changeHandler={this.handleUpdates} label='Last Name' type='text' required/>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs='12'>
                      <BaseInput name='email' startingValue={this.state.selectedUser.email} disabled={!this.state.isAddFormVisible} changeHandler={this.handleUpdates} label='Email' type='email' required/>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs='6'>
                      <BaseInput name='phonenumber' startingValue={this.state.selectedUser.phonenumber} changeHandler={this.handleUpdates} label='Phone Number' type='text'/>
                    </Col>
                    <Col xs='6'>
                      <BaseInput name='baserate' startingValue={Helpers.FormatDollars(this.state.selectedUser.baserate)} changeHandler={this.handleUpdates} label='Base Rate' type='text'/>
                    </Col>
                  </Row>
                  <Row hidden={this.state.isAddFormVisible}>
                    <Col xs='6'>
                      <BaseInput name='password' startingValue={'•••••••••'} disabled={true} changeHandler={this.handleUpdates} label='Password' type='text' required/>
                    </Col>
                    <Col xs='6'>
                    <div className='temp-offset-for-input'>
                      <BaseButton content='Reset' onClick={this.resetUserPw}/>
                    </div>
                    </Col>
                  </Row>

                  <Row style={{marginTop: '30px'}}>
                    <Col xs='12' style={{textAlign: 'left'}}>
                      <p className='temp-subsection'>Permissions</p>
                    </Col>
                  </Row>

                  {/* STYLED CHECKBOXES */}
                  <Row>
                    <Col xs='12' style={{textAlign: 'left'}}>
                      <span>
                        <input type='checkbox' name='manageinvoices' checked={this.state.selectedUser.manageinvoices} onChange={this.permissionChanged} className='temp-checkbox'/>
                        <p style={{display: 'inline-block'}}>Manage <span id='invoicetarget' style={{textDecoration: "underline", color:"blue"}}>Invoices</span></p>
                        <UncontrolledTooltip target='invoicetarget' placement='right'>Allow user to create and edit Quick Books Invoices through HaasTime.</UncontrolledTooltip>
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs='12' style={{textAlign: 'left'}}>
                      <span>
                        <input name='manageprojects' type='checkbox' checked={this.state.selectedUser.manageprojects} onChange={this.permissionChanged} className='temp-checkbox'/>
                        <p style={{display: 'inline-block'}}>Manage <span id='projectstarget' style={{textDecoration: "underline", color:"blue"}}>Projects</span></p>
                        <UncontrolledTooltip target='projectstarget' placement='right'>Allow user to create, edit, and archive Projects; view additional details for Manage Projects and Reports.</UncontrolledTooltip>
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs='12' style={{textAlign: 'left'}}>
                      <span>
                        <input name='manageusers' type='checkbox' checked={this.state.selectedUser.manageusers} onChange={this.permissionChanged} className='temp-checkbox'/>
                        <p style={{display: 'inline-block'}}>Manage <span id='userstarget' style={{textDecoration: "underline", color:"blue"}}>Users</span></p>
                        <UncontrolledTooltip target='userstarget' placement='right'>Allow users to create, edit, and delete User accounts. This includes User permissions.</UncontrolledTooltip>
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs='12' style={{textAlign: 'left'}}>
                      <span>
                        <input type='checkbox' name='desktopaccess' checked={this.state.selectedUser.desktopaccess} onChange={this.permissionChanged} className='temp-checkbox'/>
                        <p style={{display: 'inline-block'}}>Desktop <span id='desktopaccesstarget' style={{textDecoration: "underline", color:"blue"}}>Access</span></p>
                        <UncontrolledTooltip target='desktopaccesstarget' placement='right'>Allow users to access to HaasTime for Desktop.</UncontrolledTooltip>
                      </span>
                    </Col>
                  </Row>
                  <ConditionalDOM render={this.props.user.managesystemsettings}>
                    <Row>
                      <Col xs='12' style={{textAlign: 'left'}}>
                        <span>
                          <input type='checkbox' name='managesystemsettings' checked={this.state.selectedUser.managesystemsettings} onChange={this.permissionChanged} className='temp-checkbox'/>
                          <p style={{display: 'inline-block'}}>Manage <span id='managesystemsettingstarget' style={{textDecoration: "underline", color:"blue"}}>System Settings</span></p>
                          <UncontrolledTooltip target='managesystemsettingstarget' placement='right'>Allow users to change certain System Settings, like the Accountant Email address that receives new reimbursables.</UncontrolledTooltip>
                        </span>
                      </Col>
                    </Row>
                  </ConditionalDOM>

                  {/* ACTIONS */}
                  {
                    this.state.isAddFormVisible ? 
                      <div style={{marginTop: '20px'}}>
                          <BaseButton content='Create User' onClick={this.createUser}/>
                      </div> :
                      <Row style={{marginTop: '20px'}}>
                        <Col xs='6'>
                          <BaseButton content='Update User' onClick={this.updateUser}/>
                        </Col>
                        <Col xs='6'>
                          <BaseButton content='Delete User' classes='delete-button' onClick={this.deleteUser}/>
                        </Col>
                      </Row>
                  }
                </div>
                : 
                null
            }
          </div>

          <ConditionalDOM render={this.props.user.managesystemsettings && !this.state.selectedUser.iduser}>
            <div className='content-card singular-card' style={{width: '800px', marginBottom: '25px'}}>
              <div>
                <p className='page-header'>Accounting Email</p>
              </div>
              <div>
                <p className='temp-offset-for-input'>In order to update the email address to which Reimbursables are sent, enter the new address below, and press 'Update.'
                  The current email address is not shown for security reasons.</p>
                <Row hidden={this.state.isAddFormVisible}>
                  <Col xs='6'>
                    <BaseInput name='accountingemail' changeHandler={this.customChangeHandler} label='Accounting Email' type='email' required/>
                  </Col>
                  <Col xs='6'>
                  <div className='temp-offset-for-input'>
                    <BaseButton content='Update' onClick={this.updateAccountantEmail}/>
                  </div>
                  </Col>
                </Row>
              </div>
            </div>
          </ConditionalDOM>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
    return {
      user: state.user.session,
      users: state.user.list
    }
  }
const mapDispatchToProps = (dispatch) => {
  return {
    setUserSession: (obj) => dispatch(actions.setUserSession(obj)),
    logout: () => dispatch(actions.logout()),
    showLoading: () => dispatch(actions.showLoading()),
    closeLoading: () => dispatch(actions.closeLoading()),
    setUserList: (arr) => dispatch(actions.setUserList(arr))
  }
}
  
ManageUsers.propTypes = { 
  user: PropTypes.object.isRequired
}
  
  export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);