import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// Components
import Header from './components/Header';
import LoadingWidget from './components/LoadingWidget';
// Pages
import * as pages from './pages';
import { urls } from './pages';
// Styles
import './style/index.css';
import './style/App.css';
import './style/Menu.css';


class App extends Component{
  render() {
    return (
      <React.Fragment>
        <Router>
          <Header/>
          <div className='container'>
            <Switch>
              <Route exact path={urls.login} component={ pages.Login }/>
              <Route exact path={urls.forgotPassword} component={ pages.ForgotPassword }/>
              <Route exact path={urls.home} component={ pages.Home }/>
              <Route exact path={urls.timeLogs} component={ pages.TimeLogs }/>
              <Route exact path={urls.reimbursables} component={ pages.Reimbursables }/>
              <Route exact path={urls.history} component={ pages.History }/>
              <Route exact path={urls.reports} component={ pages.Reports }/>
              <Route exact path={urls.manageProjects} component={ pages.ManageProjects }/>
              <Route exact path={urls.manageUsers} component={ pages.ManageUsers }/>
              <Route exact path={urls.manageInvoices} component={ pages.ManageInvoices }/>
              <Route exact path={urls.oAuthComplete} component={ pages.OAuthComplete }/>
              <Route exact path={urls.profile} component={ pages.Profile }/>
              {/* fatal errors and unmatched paths */}
              <Route component={ pages.Error }/>
            </Switch>
          </div>
        </Router>
        <LoadingWidget/>
      </React.Fragment>
    )
  }
}

export default App;
