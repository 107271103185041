import React, { Component } from 'react';
import ApiHelper from '../utilities/apiHelper';
import { Endpoints } from '../Constants';
import Helpers from '../utilities/helpers';
import { ConditionalDOM } from '../components';

class Timer extends Component {
  constructor(props){
    super(props);

    let startSec = 0;
    if (props.timeLog.duration && props.timeLog.duration > 0){
      if (props.timeLog.state === 'paused' || props.timeLog.state === 'stopped'){
        startSec = props.timeLog.duration;
      } else if (props.timeLog.state === 'timer') {
        let modified = new Date(props.timeLog.lastmodified);
        startSec = props.timeLog.duration + Math.floor((new Date() - modified) / 1000);
      } else {
        console.error('Now, it\'t truly an invalid state... ' + props.timeLog.state);
      }
    } else {
      let created = new Date(props.timeLog.created);
      startSec = Math.floor((new Date() - created) / 1000);
    }

    let timeText = null;

    if (props.timeLog.state === 'timer'){
      this.timer = setInterval(this.setTime, 1000);
    } else if (props.timeLog.state === 'stopped'){
      timeText = props.timeLog.durationhrs
    }

    this.state = {
      timeLog: props.timeLog,
      project: props.timeLog.project,
      totalSeconds: startSec,
      timeText: timeText ? timeText : Helpers.FormatTimeText(startSec)
    };
  }
  componentWillUnmount = () => {
    clearInterval(this.timer);
  }

  setTime = () => {
    this.setState((prevState) => {
      let totalSeconds = prevState.totalSeconds + 1;
      let timeText = Helpers.FormatTimeText(totalSeconds);
      return { totalSeconds, timeText }
    });
  }

  //METHODS
  navigateToEdit = (e) => {
    if (!this.props.onClick){
      return;
    }
    this.props.onClick(this.state.timeLog.idtimelog);
  }
  pauseClicked = async (e) => {
    e.stopPropagation();

    let timeLog = this.state.timeLog;
    timeLog.duration = this.state.totalSeconds;
    timeLog.state = 'paused';

    this.setState({ timeLog });

    let response = await ApiHelper.put(Endpoints.TimeLogs, timeLog);

    if (response.success){
      clearInterval(this.timer);
    } else {
      alert('Failed to pause the timer.');
    }
  }
  playClicked = async (e) => {
    e.stopPropagation();

    let timeLog = this.state.timeLog;
    timeLog.duration = this.state.totalSeconds;
    timeLog.state = 'timer';

    this.setState({ timeLog });

    let response = await ApiHelper.put(Endpoints.TimeLogs, timeLog);

    if (response.success){
      this.timer = setInterval(this.setTime, 1000);
    } else {
      alert('Failed to pause the timer.');
    }
  }
  stopClicked = async (e) => {
    e.stopPropagation();

    let timeLog = this.state.timeLog;
    timeLog.duration = this.state.totalSeconds;
    timeLog.state = 'stopped';

    this.setState({ timeLog });

    let response = await ApiHelper.put(Endpoints.TimeLogs, timeLog);

    if (response.success){
      this.props.timerStatusChanged();
    } else {
      alert('Failed to clock out.');
    }
  }

  //THE RENDER FUNCTION
  render() {
    return (
      <div className='item-container timer-flex with-shadow' onClick={this.navigateToEdit}>
        <p className='temp-data-label'>{this.state.project.title} ({this.state.timeText})</p>
        <div style={{display: 'block'}}>
          <ConditionalDOM render={this.state.timeLog.state === 'timer'}>
            <button className='brgr-wrapper fa fa-pause' onClick={this.pauseClicked} style={{color: '#000000', fontSize: '22px', backgroundColor: 'transparent', border: 'none', boxShadow: 'none', outline: 'none'}}/>
          </ConditionalDOM>
          <ConditionalDOM render={this.state.timeLog.state !== 'timer'}>
            <button className='brgr-wrapper fa fa-play' onClick={this.playClicked} style={{color: '#000000', fontSize: '22px', backgroundColor: 'transparent', border: 'none', boxShadow: 'none', outline: 'none'}}/>
          </ConditionalDOM>
          <button className='brgr-wrapper fa fa-stop' onClick={this.stopClicked} style={{color: '#000000', fontSize: '22px', backgroundColor: 'transparent', border: 'none', boxShadow: 'none', outline: 'none', marginLeft: '15px'}}/>
        </div>
      </div>
    )
  }
}

export default Timer;