import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as actions from '../actions';
import { Endpoints } from '../Constants';
import { urls } from '.';
import ApiHelper from '../utilities/apiHelper';
import Helpers from '../utilities/helpers.js';
import { BaseButton, BaseInput } from '../components';

class ForgotPassword extends Component {
  //LIFECYCLE
  constructor(props){
    super(props);
    this.state = {
      errorMessage: ''
    };
  }

  //METHODS
  resetPassword = async (e) => {
    e.preventDefault();
    if (!Helpers.IsValidEmail(this.state.email)){
      alert('Please enter a valid email address.');
      return;
    }

    this.props.showLoading();
    let response = await ApiHelper.post(Endpoints.ForgotPassword, {email: this.state.email});

    if (response.success){
      alert('Your password was reset successfully. Please check your email for your temporary password.');
    } else if (response.status === 404){
      this.showErrorText('That email was not found in the system.');
    } else {
      this.showErrorText('Something went wrong while processing your request.');
    }
    this.props.closeLoading();
  }
  showErrorText = (msg) => {
    this.setState({ errorMessage: msg });
  }

  //EVENT HANDLERS
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errorMessage: ''
    });
  }
  handleUpdates = (name, value) => {
    this.setState({[name]: value});
  }
  //RENDER
  render() {
    return (
      <div className='content-view'>
        <div className='centered-container'>
          <div className='content-card singular-card'>
            <div style={{width: '100%'}}>
              <img src='../images/logo.jpeg' height='140px' alt='Haas Logo'/>
            </div>
            <form onSubmit={this.resetPassword}>
              <BaseInput name='email' changeHandler={this.handleUpdates} label='Email' type='email' required autoFocus={true}/>
              <BaseButton content='Reset Password' type='submit'/>
              <p hidden={this.state.errorMessage.length < 1} className='err-text'>{this.state.errorMessage}</p>
            </form>
            <div style={{textAlign: 'right', marginTop: '10px'}}>
                <Link to={urls.login}>Return to Login page</Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.session
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
    showLoading: () => dispatch(actions.showLoading()),
    closeLoading: () => dispatch(actions.closeLoading())
  }
}

ForgotPassword.propTypes = { 
  user: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);